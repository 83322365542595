import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import makeStyles from '@mui/styles/makeStyles';
import Credentials from './Credentials';
import VerificationCode from './VerificationCode';
import EnrollOtpAuth from './EnrollOtpAuth';
import Link from '../Link';
import { notify } from '../../actions/ui';
import {
  sendOtpSms,
  setBasicAuth,
  sendOtp,
  getOtpConfig,
} from '../../actions/auth';
import login from '../../core/login';
import { white } from '../../core/colors';
import Dialog from '../Dialog/Dialog';
import { TransportError, UnauthorizedError } from '../../core/apiTransport';

const useStyles = makeStyles({
  loginErrorLink: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: white,
    textDecoration: 'underline',
  },
  LoginErrorSubText: {
    fontSize: 14,
  },
});

function Login({
  initialErrors,
  notify,
  sendOtpSms,
  setBasicAuth,
  sendOtp,
  getOtpConfig,
  ...props
}) {
  const s = useStyles();
  const [twoFactorStep, setTwoFactorStep] = useState();
  const [enrollTwoFactorStep, setEnrollTwoFactorStep] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState([]);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [otpConfiguration, setOtpConfiguration] = useState(null);
  const [finalAttemptDialogOpen, setFinalAttemptDialogOpen] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonResendDisable, setButtonResendDisable] = useState(false);

  // AlertId used in the automation tests
  const [alertId, setAlertId] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return Promise.resolve();
    }

    const token = await executeRecaptcha('login');
    setRecaptchaToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleEmail = useCallback((e) => setEmail(e.target.value), []);
  const handlePassword = useCallback((e) => setPassword(e.target.value), []);
  const handleOtp = useCallback((e) => setOtp(e.target.value), []);

  const handleCancel = useCallback(() => {
    setOtp('');
    setErrors([]);
    setAlertId(null);
    setTwoFactorStep(false);
    setEnrollTwoFactorStep(false);
    setOtpConfiguration(null);
    setButtonDisable(false);
    setButtonResendDisable(false);
  }, []);

  const securityEmail = 'hello@onfrontiers.com';

  const handleResend = useCallback(async () => {
    try {
      await setButtonResendDisable(true);
      await sendOtp(email, password);
      notify(`Verification code sent to ${otpConfiguration.maskedAddress}`);
    } catch (err) {
      notify('An error occurred when trying to setup otp.', 'error');
      throw err;
    }
  }, [otpConfiguration]);

  const handleSubmit = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      await setButtonDisable(true);

      const next = props.nextUrl;
      setEnrollTwoFactorStep(false);
      try {
        await login({ email, password, otp, next, recaptchaToken });
      } catch (err) {
        handleReCaptchaVerify();

        let message;
        let allowRetry = false;

        if (err instanceof TransportError) {
          console.error(err);
          message = 'Sorry, the service is currently unavailable.';
          allowRetry = true;
        } else if (err instanceof UnauthorizedError) {
          switch (err.message) {
            case 'invalid_credentials':
              console.log(err, err.remainingAttempts);
              message = 'Invalid username or password';
              if (err.remainingAttempts === 1) {
                setFinalAttemptDialogOpen(true);
              }
              if (err.remainingAttempts > 0 && err.remainingAttempts < 3) {
                message = (
                  <span>
                    {message}. To prevent your account from being locked,&nbsp;
                    <Link className={s.loginErrorLink} to="/password_reset">
                      recover your password
                    </Link>
                  </span>
                );
              }
              break;
            case 'locked_for_interval':
              message = (
                <span>
                  Your account has been locked for 24 hours
                  <br />
                  <div className={s.LoginErrorSubText}>
                    If you need urgent assistance please email&nbsp;
                    <a
                      className={s.loginErrorLink}
                      href={`mailto:${securityEmail}`}
                    >
                      {securityEmail}
                    </a>
                  </div>
                </span>
              );
              break;
            case 'otp_enrollment_required':
              message = 'Two-factor authentication is required';
              if (!enrollTwoFactorStep) {
                await setBasicAuth(email, password);
                setEnrollTwoFactorStep(true);
              }
              break;
            case 'otp_required':
              message = 'Invalid verification code';
              if (!twoFactorStep) {
                await sendOtp(email, password);
                setOtpConfiguration(await getOtpConfig(email, password));
                setTwoFactorStep(true);
              }
              break;
            default:
              message = 'Unknown authorization error.';
          }
        } else if (err instanceof APIError) {
          console.error(err);
          message = 'Sorry, unknown error.';
          allowRetry = true;
        } else {
          throw err;
        }

        setAlertId('invalidLoginAlert');
        if (allowRetry) {
          setTimeout(() => {
            setButtonDisable(false);
          }, 1000);
        }
        setErrors(message ? [message] : []);
        setOtp('');
      }
    },
    [
      email,
      password,
      otp,
      recaptchaToken,
      enrollTwoFactorStep,
      handleReCaptchaVerify,
      twoFactorStep,
      buttonDisable,
      props.nextUrl,
      props.signupType,
      props.invite,
    ]
  );

  const allErrors = [...errors, ...initialErrors];

  return enrollTwoFactorStep ? (
    <EnrollOtpAuth
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      setOtp={setOtp}
      email={email}
    />
  ) : twoFactorStep ? (
    <VerificationCode
      code={otp}
      onChange={handleOtp}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onResend={handleResend}
      buttonResendDisable={buttonResendDisable}
      error={errors[0]}
      otpConfiguration={otpConfiguration}
    />
  ) : (
    <>
      <Credentials
        {...props}
        email={email}
        password={password}
        onEmailChange={handleEmail}
        onPasswordChange={handlePassword}
        onSubmit={handleSubmit}
        errors={allErrors}
        buttonDisable={buttonDisable || (!recaptchaToken && executeRecaptcha)}
        alertId={alertId}
      />
      <Dialog
        PaperProps={{
          id: 'invalidUserPasswordPopUp',
        }}
        open={finalAttemptDialogOpen}
        onCancel={() => {
          setFinalAttemptDialogOpen(false);
        }}
      >
        <span>
          You only have one remaining opportunity to input your password before
          you are locked out.&nbsp;
          <Link to="/password_reset">Recover your password</Link>.
        </span>
      </Dialog>
    </>
  );
}

Login.defaultProps = {
  initialErrors: [],
  showTitle: true,
  enrollTwoFactorStep: false,
  twoFactorStep: false,
};

Login = connect(
  (state) => ({
    initialErrors: state.runtime.errors,
  }),
  {
    notify,
    sendOtpSms,
    sendOtp,
    setBasicAuth,
    getOtpConfig,
  }
)(Login);

export default Login;
