import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';
import Waypoint from 'react-waypoint';
import qs from 'query-string';
import Image from '../Image';
import Divider from '../Divider';
import SocialButton from '../SocialButton';
import Tidbit from '../Tidbit';
import RequestConsultationButton from '../Button/RequestConsultationButton';
import ProfileCompleteness from '../ProfileCompleteness';
import SendMessageButton from '../SendMessageButton';
import Badges from './Badges';
import Questions from './Questions';
import Sidebar from './Sidebar';
import Expertise from './Expertise';
import Education from './Education';
import Experience from './Experience';
import Headline from './Headline';
import Summary from './Summary';
import Stats from './Stats';
import ActivityLog from '../ActivityLog';
import AdminModule from './AdminModule';
import AddToExpertRequestButton from '../AddToExpertRequestButton';
import Dialogs from './Dialogs';
import CvButton from '../CvButton';
import EditIcon from '../EditIcon';
import {
  updateProfile,
  updatePicture,
  updateCover,
} from '../../actions/profile';
import { openPictureDialog, openResumeDialog, popup } from '../../actions/ui';
import { track } from '../../actions/tracking';
import { formatCredits } from '../../core/util';
import { promoPopup } from '../SendMessageButton/promo';
import Cover from './cover.png';
import { canRequestConsultation } from '../../core/consultation';
import { useApp } from '../../hooks/useAppContext';
import { darkGreen, white } from '../../core/colors';
import Keywords from './Keywords';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  cover: {
    position: 'relative',
    height: 160,
    overflow: 'hidden',
    backgroundColor: darkGreen,
    [theme.breakpoints.down('md')]: {
      height: 125,
    },
  },
  unregisteredTagContainer: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  unregisteredTag: {
    display: 'inline-block',
    marginTop: 20,
    color: darkGreen,
    fontSize: 18,
    border: `1px solid ${darkGreen}`,
    borderRadius: 2,
    padding: '5px 20px',
    [theme.breakpoints.down('md')]: {
      margin: '0 0 15px',
      fontSize: 14,
      padding: 5,
    },
  },
  main: {
    maxWidth: 1200,
    margin: '0 auto',
    display: 'flex',
    padding: '0 20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '0 10px',
    },
  },
  content: {
    flex: '1 0 0%',
    padding: '0 0 30px 30px',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      flex: '1 0 auto',
    },
  },
  cvButton: {
    margin: '25px 0',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'flex-start',
  },
  stickyConsultationButton: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: white,
    width: 'calc(100% - 20px)',
    textAlign: 'center',
    padding: 10,
    zIndex: 2,
  },
  consultationButton: {
    backgroundColor: white,
    paddingTop: 30,
  },
  flexJustifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  sendMessageButton: {
    textAlign: 'center',
    marginTop: 10,
  },

  tidbit: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  footer: {
    marginTop: 30,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  paddingMobile: {
    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
    },
  },
}));

const useRequestButtonStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 10,
      width: 50,
      minWidth: 50,
    },
  },
  startIcon: {
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
}));

function Profile({
  popup,
  track,
  messagingPromo,
  openedDialog,
  updatePicture,
  updateCover,
  openPictureDialog,
  openResumeDialog,
  updateProfile,
  editable,
  self,
  viewer,
  user,
  profile,
  profileId,
  expertRequestId,
}) {
  const { permissions, store } = useApp();
  const navigate = useNavigate();

  const s = useStyles();
  const requestButtonStyles = useRequestButtonStyles();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const desktop = !mobile;

  const [editSections, setEditSections] = useState({});
  const [stickConsultationButton, setStickConsultationButton] = useState(true);
  const [completenessClosed, setCompletenessClosed] = useState(false);

  useEffect(() => {
    if (openedDialog) {
      openDialog(openedDialog);
    }

    if (messagingPromo) {
      promoPopup(popup, track);
    }
  }, []);

  const handleUpdatePicture = useCallback(
    (file) => {
      updatePicture(profileId, file.url);
    },
    [profileId, updatePicture]
  );

  const handleUpdateCover = useCallback(
    (file) => {
      updateCover(profileId, file.url);
    },
    [profileId, updatePicture]
  );

  const getSectionsInitialState = useCallback(() => {
    const sections = { ...editSections };
    Object.keys(sections).forEach((k) => {
      sections[k] = false;
    });
    return sections;
  }, [editSections]);

  const openDialog = useCallback(
    (key) => {
      setEditSections({
        ...getSectionsInitialState(),
        [key]: true,
      });
    },
    [editSections, setEditSections, getSectionsInitialState]
  );

  const closeDialog = useCallback(() => {
    setEditSections(getSectionsInitialState());
  }, [editSections, setEditSections, getSectionsInitialState]);

  const attachCV = useCallback(
    (file) => {
      if (!file) return;
      updateProfile({ id: profileId, cv_url: file.url });
    },
    [profileId, updateProfile]
  );

  const removeCV = useCallback(() => {
    updateProfile({ id: profileId, cv_url: '' });
  }, [profileId, updateProfile]);

  const handlePictureDialog = () => {
    openPictureDialog().then(handleUpdatePicture);
  };

  const handleResumeDialog = () => {
    openResumeDialog().then(attachCV);
  };

  const canEditGroupKeywords = useMemo(
    () =>
      permissions.allowed('group_member', 'update_group_keywords', profileId),
    [profileId]
  );

  const requestUrl = useMemo(() => {
    const expertRequestParam = expertRequestId
      ? `?${qs.stringify({ expertRequestId })}`
      : '';
    return `/profile/${profile?.url_endpoint}/request${expertRequestParam}`;
  }, [expertRequestId, profile]);

  const showMessageButton = useMemo(
    () =>
      viewer.id &&
      !self &&
      user &&
      (permissions.allowed('promo', 'see_start_chat', user.id) ||
        permissions.allowed('messaging', 'start_non_anonymous_chat', user.id)),
    [viewer, self, user]
  );

  if (!profile) return null;

  const { credit_rate: creditRate, regions, sectors } = profile;

  const coverHeight = mobile ? 120 : 160;
  const credits = user && formatCredits(creditRate);
  const isAvailableSelfService = !self && user && user.available_self_service;
  const canAddToRequest = viewer.admin || isAvailableSelfService;
  const canViewBooking = canAddToRequest;
  const hasExpertise = !!(regions.length || sectors.length);

  const adminModule = viewer.admin && (
    <AdminModule
      user={user}
      profile={profile}
      style={{
        marginTop: 30,
        maxWidth: desktop ? 277 : 'initial',
      }}
      onEditMarketplace={() => openDialog('available_marketplace')}
      onEditSelfService={() => openDialog('available_self_service')}
      onEditBackgroundCheck={() => openDialog('background_check')}
      onEditRate={() => openDialog('rate')}
      onEditEmail={() => openDialog('emails')}
      onEditPhone={() => openDialog(user ? 'communication' : 'phones')}
      onEditTimezone={() => openDialog('communication')}
      onEditSkype={() => openDialog('communication')}
      onEditAdditionalInformation={() => openDialog('additional_information')}
    />
  );

  const mobileActions = mobile && canRequestConsultation(user, profile) && (
    <div className={s.flexJustifyCenter}>
      <RequestConsultationButton onClick={() => navigate(requestUrl)} />

      {canAddToRequest && (
        <AddToExpertRequestButton
          size="large"
          label=""
          profile={profile}
          classes={requestButtonStyles}
          form="addToExpertRequestFromProfile"
        />
      )}
    </div>
  );

  return (
    <div>
      {mobileActions && stickConsultationButton && (
        <div className={s.stickyConsultationButton}>{mobileActions}</div>
      )}

      <div className={s.cover}>
        {user && (
          <Image
            src={profile.cover_url || Cover}
            style={{ position: 'initial' }}
            imageStyle={{
              width: '100%',
              margin: '0 auto',
              minWidth: 1200,
              minHeight: coverHeight,
            }}
            overlayStyle={{ top: mobile ? 0 : 125 }}
            editable={editable}
            onChange={handleUpdateCover}
            dimensions={{ width: 1600, height: 214 }}
          />
        )}
      </div>

      <div className={s.main}>
        <div>
          <Sidebar
            user={user}
            profile={profile}
            credits={credits}
            onEditName={editable && (() => openDialog('name'))}
            onEditLinkedIn={editable && (() => openDialog('linkedin'))}
            onPictureChange={handleUpdatePicture}
            pictureEditable={editable}
            requestUrl={requestUrl}
            showMessageButton={showMessageButton}
            canAddToRequest={canAddToRequest}
            canViewBooking={canViewBooking}
            canRequestConsultation={canRequestConsultation(user, profile)}
            style={mobile ? {} : { marginTop: -120, minWidth: 237 }}
          />

          {desktop && adminModule}
        </div>

        <div className={s.content}>
          {!user && (
            <div className={s.unregisteredTagContainer}>
              <div className={s.unregisteredTag}>Unregistered Expert</div>
            </div>
          )}

          {editable && !completenessClosed && self && (
            <ProfileCompleteness
              profile={profile}
              onRequestClose={() => setCompletenessClosed(true)}
              style={{ marginTop: 20, marginBottom: 20 }}
              nameAction={() => openDialog('name')}
              pictureAction={handlePictureDialog}
              introductionAction={() => openDialog('headline')}
              experiencesAction={() => openDialog('experiences')}
              keywordsAction={() => openDialog('keywords')}
              expertiseAction={() => openDialog('expertise')}
              questionsAction={() => openDialog('questions')}
              locationAction={() => openDialog('location')}
              communicationAction={() => openDialog('communication')}
              phoneAction={() => openDialog('phones')}
              educationsAction={() => openDialog('education')}
              linkedinAction={() => openDialog('linkedin')}
              resumeAction={handleResumeDialog}
            />
          )}

          <Badges
            viewer={viewer}
            user={user}
            profile={profile}
            onEditMarketplace={() => openDialog('available_marketplace')}
          />

          {user && <Stats user={user} />}

          <div className={cx({ [s.row]: desktop })}>
            <Tidbit
              className={s.tidbit}
              city={profile.city}
              country={profile.country}
              languages={profile.languages}
              editable={editable}
              inline={desktop}
              onEditLocation={() => openDialog('location')}
              onEditLanguages={() => openDialog('communication')}
            />

            {(profile.linkedin_url || editable) && desktop && (
              <div className={s.flexJustifyCenter}>
                <SocialButton
                  startIcon="linkedin"
                  href={profile.linkedin_url}
                />
                {editable && (
                  <EditIcon
                    style={{ marginLeft: 10 }}
                    onClick={() => openDialog('linkedin')}
                  />
                )}
              </div>
            )}
          </div>

          {mobile && showMessageButton && (
            <div className={s.sendMessageButton}>
              <SendMessageButton userId={user.id} />
            </div>
          )}

          <Headline
            editable={editable}
            profile={profile}
            onEdit={() => openDialog('headline')}
          />

          {mobile && <Divider />}

          <Summary
            editable={editable}
            profile={profile}
            onEdit={() => openDialog('headline')}
          />

          <div className={s.cvButton}>
            <CvButton
              editable={editable}
              url={profile.cv_url}
              onUpload={attachCV}
              onRemove={removeCV}
            />
          </div>

          {mobile && <Divider />}

          <Questions
            profile={profile}
            editable={editable}
            onEdit={() => openDialog('questions')}
          />

          <Divider />

          <Expertise
            profile={profile}
            editable={editable}
            onEdit={() => openDialog('expertise')}
          />

          {(hasExpertise || editable) && <Divider />}

          <Keywords
            viewer={viewer}
            showSections
            profile={profile}
            editable={editable || canEditGroupKeywords}
            onEdit={() => openDialog('keywords')}
          />

          <Divider />

          <Experience
            experiences={profile.experiences}
            editable={editable}
            onEdit={() => openDialog('experiences')}
          />

          <Divider />

          <Education
            education={profile.education}
            editable={editable}
            onEdit={() => openDialog('education')}
          />

          <Divider />

          {user && (
            <ActivityLog
              objectType="user"
              objectId={user.id}
              actions={[
                'joined_platform',
                'candidate_add',
                'candidate_answers_set',
                'candidate_update',
                'consultation_requested',
                'consultation_confirmed',
                'consultation_closed',
                'inetexpert_add',
              ]}
              store={store}
            />
          )}

          {mobileActions && (
            <Waypoint
              bottomOffset={70}
              onEnter={() => setStickConsultationButton(false)}
              onLeave={(e) =>
                setStickConsultationButton(e.currentPosition === Waypoint.below)
              }
            >
              <div className={s.consultationButton}>{mobileActions}</div>
            </Waypoint>
          )}

          {mobile && adminModule}

          <Dialogs
            user={user}
            profile={profile}
            editSections={editSections}
            closeDialog={closeDialog}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    viewer: state.viewer,
    user: ownProps.userId ? state.users[ownProps.userId] : undefined,
    profile: state.profiles.fullProfiles[ownProps.profileId],
  }),
  {
    updateProfile,
    updatePicture,
    updateCover,
    openPictureDialog,
    openResumeDialog,
    popup,
    track,
  }
)(Profile);
