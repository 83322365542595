import React from 'react';
import { redirect, useLoaderData } from 'react-router-dom';
import {
  fetchPublicExpertRequest,
  requestAddExpertRequestCandidate,
} from '../../actions/expertRequest';
import { WrapperComponent } from '../../components/WrapperComponent';
import { action as privateExpertsRequestAction } from '../expertRequestDetails';
import ExpertAuthPage from './ExpertAuthPage';
import ExpertRequestAddRequest from './ExpertRequestAddRequest';
import ExpertRequestAddRequestSubmitted from './ExpertRequestAddRequestSubmitted';
import ExpertRequestPublic from './ExpertRequestPublic';
import ReferralAuthPage from './ReferralAuthPage';

const path = '/expert_request/:id';
const ALREADY_ADDED = 'GraphQL Error: expert request candidate already added';

function findExpertRequest(store, id) {
  return store.dispatch(fetchPublicExpertRequest(id));
}

function RequestWrapper() {
  const { viewer, expertRequest, signup, tags } = useLoaderData();

  if (!viewer && signup) {
    return (
      <ExpertAuthPage
        expertRequest={expertRequest}
        signup={signup}
        tags={tags}
      />
    );
  }

  if (viewer && expertRequest) {
    return (
      <ExpertRequestAddRequest viewer={viewer} expertRequest={expertRequest} />
    );
  }

  return <ExpertRequestAddRequestSubmitted viewer={viewer} />;
}

function ExpertsRequestComponent() {
  const data = useLoaderData();
  if (data.public) {
    return <ExpertRequestPublic {...data} />;
  }

  return <WrapperComponent />;
}

async function publicExpertsRequestAction({ store, id, query }) {
  const expertRequest = await findExpertRequest(store, id);
  if (!expertRequest) return redirect('/404');

  document.title = 'Expert Request';
  return {
    public: true,
    expertRequest,
    action: query.action,
    tags: query.t,
    meta: {
      description: expertRequest.description,
      imageUrl: require('./promo.jpg'),
      url: expertRequest.public_html_url,
    },
  };
}

export default {
  path,
  title: 'Expert Request',
  children: [
    {
      path,
      element: <ExpertsRequestComponent />,
      async action({ store, params, location, query }) {
        const { id } = params;

        if (isNaN(id)) {
          return publicExpertsRequestAction({ store, id, query });
        }

        return privateExpertsRequestAction({ store, location, query, id });
      },
    },
    {
      path: `${path}/request_add/:auth?/:signupType?`,
      element: <RequestWrapper />,
      async action({ store, params, query }) {
        const { id, auth } = params;
        document.title = 'Expert Request';

        const expertRequest = await findExpertRequest(store, id);
        if (!expertRequest) return redirect('/404');

        const { viewer } = store.getState();
        if (!viewer.id && !auth) {
          return redirect(
            `/expert_request/${expertRequest.slug}/request_add/signup`
          );
        }

        if (viewer.id && auth) {
          return redirect(`/expert_request/${expertRequest.slug}/request_add`);
        }

        if (!viewer.id && auth) {
          return {
            expertRequest,
            signup: auth === 'signup',
            tags: query.t,
          };
        }

        try {
          await store.dispatch(
            requestAddExpertRequestCandidate({
              expert_request_id: id,
            })
          );
        } catch (err) {
          if (err.message === ALREADY_ADDED) {
            return { viewer };
          }
          throw err;
        }

        return {
          viewer,
          expertRequest,
        };
      },
    },
    {
      path: `${path}/refer/:auth?/:signupType?`,
      element: <ReferralAuthPage />,
      async action({ store, params, query }) {
        const { id, auth } = params;
        document.title = 'Expert Request';

        const expertRequest = await findExpertRequest(store, id);
        if (!expertRequest) return redirect('/404');

        const { viewer } = store.getState();
        if (!viewer.id && !auth) {
          return redirect(`/expert_request/${expertRequest.slug}/refer/signup`);
        }

        if (viewer.id) {
          return redirect(`/expert_request/${expertRequest.slug}`);
        }

        return {
          expertRequest: expertRequest,
          signup: auth === 'signup',
          tags: query.t,
        };
      },
    },
  ],
};
