import React from 'react';
import { fetchStarting } from '../../actions/consultation';
import {
  fetchExpertRequest,
  fetchExpertRequestCandidates,
  updateExpertRequestCandidate,
} from '../../actions/expertRequest';
import { awaitingProjectMembershipApproval } from '../../actions/project';
import { track } from '../../actions/tracking';
import InaccessibleArchived from '../../components/InaccessibleArchived';
import LayoutPage from '../../components/Layout/LayoutPage';
import RequestProjectAccess from '../../components/RequestProjectAccess';
import ExpertRequest from './ExpertRequest';
import {
  redirectIfAgreementsNotAccepted,
  redirectIfEmailNotVerified,
} from '../routesMiddleware';
import { redirect } from 'react-router-dom';

function requestAdd(query, viewer, id, accessRequested) {
  document.title = 'Expert Request';
  return (
    <LayoutPage showNav selected="expert_requests">
      <RequestProjectAccess
        path="expert_request"
        query={query}
        expertRequestId={id}
        viewer={viewer}
        accessRequested={accessRequested}
      />
    </LayoutPage>
  );
}

export const action = async ({ store, location, query, id }) => {
  const shouldRedirect =
    redirectIfEmailNotVerified(store) ||
    redirectIfAgreementsNotAccepted(store, location);

  if (shouldRedirect) return shouldRedirect;

  const { viewer } = store.getState();

  if (!viewer.id) return requestAdd(query, viewer, id, false);

  let expertRequest;
  try {
    expertRequest = await store.dispatch(fetchExpertRequest(id));
    await Promise.all(
      [
        fetchExpertRequestCandidates(id, 'suggested', true),
        fetchExpertRequestCandidates(id, 'matched', true),
        fetchStarting(),
      ].map(store.dispatch)
    );
  } catch (e) {
    if (!e.isPermissionError) throw e;
    const accessRequested = await store.dispatch(
      awaitingProjectMembershipApproval({ expertRequestId: id })
    );
    return requestAdd(query, viewer, id, accessRequested);
  }

  if (
    !expertRequest ||
    !expertRequest.permissions ||
    !expertRequest.permissions.includes('view_private')
  )
    return redirect('/404');
  if (
    expertRequest.archived &&
    !expertRequest.permissions.includes('view_archived')
  ) {
    store.dispatch(track('promo.show.request.archived'));
    document.title = expertRequest.name;
    return (
      <InaccessibleArchived
        selectedTab="expert_requests"
        entity="request"
        entities="requests"
        trackingCode="promo.chat.request.archived"
      />
    );
  }

  const {
    section: selectedSection,
    is_good_match: isGoodMatch,
    candidate_id: candidateId,
  } = query;

  if (candidateId && ['no', 'maybe', 'yes'].includes(isGoodMatch)) {
    try {
      const candidateData = {
        id: candidateId,
        client_note: { is_good_match: isGoodMatch },
      };
      if (isGoodMatch === 'yes') {
        candidateData.state = 'matched';
      }
      await store.dispatch(updateExpertRequestCandidate(id, candidateData));
    } catch (err) {
      Promise.reject(err);
    }
  }

  const targetSection = selectedSection || 'experts';

  const fetchMoreCandidates = async (type, pageInfo) => {
    if (pageInfo?.hasNextPage) {
      await store.dispatch(
        fetchExpertRequestCandidates(id, type, false, pageInfo.cursor)
      );
    }
  };

  document.title = expertRequest.name;
  return (
    <ExpertRequest
      section={targetSection}
      expertRequestId={expertRequest.id}
      candidateId={candidateId}
      isGoodMatch={isGoodMatch}
      fetchMoreCandidates={fetchMoreCandidates}
    />
  );
};
