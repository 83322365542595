import arrayMutators from 'final-form-arrays';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { redirect, useNavigate } from 'react-router-dom';
import { setAddress } from '../../actions/address';
import {
  fetchBaseExpertRequest,
  fetchExpertRequest,
  saveExpertRequest,
} from '../../actions/expertRequest';
import {
  fetchProject,
  invalidateFetchedProjectsCache,
  saveProject,
} from '../../actions/project';
import { notify, popup } from '../../actions/ui';
import Body from '../../components/Body';
import Layout from '../../components/Layout';
import MediaQuery from '../../components/MediaQuery';
import TeamAccountPromo from '../../components/TeamAccountPromo/TeamAccountPromo';
import { WrapperComponent } from '../../components/WrapperComponent';
import { SCREEN_SM } from '../../constants';
import { clearCache, getCache, queryPart, setCache } from '../../core/util';
import AddMembers from './AddMembers';
import ExpertRequestSpy from './ExpertRequestSpy';
import { copyExpertRequest, getDefaultValues } from './util';

/* eslint-disable global-require */

const baseUrl = '/request_expert';
const cacheValueKey = 'newExpertRequest';

const steps = [
  {
    path: 'type',
    component: require('./SelectType').default,
  },
  {
    path: 'account',
    component: require('./Account').default,
  },
  {
    path: 'details',
    component: require('./Details').default,
    LayoutParams: {
      showNav: true,
    },
  },
];

export const expertRequestRoot = {
  path: baseUrl,
  action: redirect(`${baseUrl}/type`),
};

export default {
  path: `${baseUrl}/:stepPath?`,
  element: <WrapperComponent />,
  async action({ store, params, query }) {
    const { viewer } = store.getState();

    if (!viewer.groups || viewer.groups.length === 0) {
      document.title = 'Upgrade your account';
      return (
        <Layout verticalCenter hideSearch showNewRequest={false}>
          <TeamAccountPromo />
        </Layout>
      );
    }

    const { stepPath } = params;

    if (!stepPath) {
      return redirect(`${baseUrl}/${steps[0].path}${queryPart(query)}`);
    }

    const step = steps.find((s) => s.path === stepPath);
    if (!step) return redirect('/404');

    const stepNumber = steps.indexOf(step);
    const nextStepURL =
      steps[stepNumber + 1] &&
      `${baseUrl}/${steps[stepNumber + 1].path}${queryPart(query)}`;

    // skip login step if user is already authenticated
    if (stepPath === 'account' && viewer.id) {
      return redirect(nextStepURL);
    }

    const { component } = step;
    if (component.fetch && Array.isArray(component.fetch)) {
      await Promise.all(
        component.fetch.map((f) => store.dispatch(f({ viewer })))
      );
    } else if (component.fetch) {
      await store.dispatch(component.fetch({ viewer }));
    }

    // if initial project_id is set, fetch the project so it can be preselected
    if (query.project_id) await store.dispatch(fetchProject(query.project_id));

    document.title = component.title;
    return <ExpertRequestNewConnected query={query} step={step} />;
  },
};

const PRIVATE_DISCLOSURE_ERROR =
  "GraphQL Error: You don't have permission to perform this action. Permission group#private_disclosure";
const ADDRESS_TAKEN_ERROR = 'GraphQL Error: address already taken';

const ExpertRequestNew = (props) => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(() => {
    const { viewer, query } = props;
    const { project_id: initialProjectId } = query;

    return getDefaultValues(viewer, initialProjectId);
  });

  const { step } = props;
  const { LayoutParams } = step;
  const stepNumber = steps.indexOf(step);

  useEffect(() => {
    const { viewer, query, fetchBaseExpertRequest, popup, step } = props;

    const { project_id: initialProjectId, copy_from: copyFrom } = query;

    if (copyFrom) {
      fetchBaseExpertRequest(copyFrom).then((copyData) => {
        if (copyData) {
          setInitialValues({
            ...copyExpertRequest(viewer, copyData),
            formInitialized: true,
          });
        }
      });
    } else {
      const defaultValues = getDefaultValues(viewer, initialProjectId);

      const cachedValue = getCache(cacheValueKey);
      const hasCachedValue = Boolean(cachedValue);

      const initDefaultValues = () => {
        const initialValues = defaultValues;
        if (defaultValues.project_id) {
          initialValues.project_id = defaultValues.project_id;
        }
        setInitialValues({ ...initialValues, formInitialized: true });
      };

      if (hasCachedValue) {
        popup({
          buttonAlignment: 'space-between',
          title: 'A previous Expert Request was found.',
          contents: 'Do you want to recover the previous expert request?',
          buttons: [
            {
              flat: true,
              label: 'No',
              callback: () => {
                clearCache(cacheValueKey);
                initDefaultValues();
              },
            },
            {
              label: 'Yes',
              callback: () => {
                cachedValue.currentVisit += 1;
                setInitialValues({ ...cachedValue, formInitialized: true });
                if (steps.indexOf(step) === 0) {
                  onNext();
                }
              },
            },
          ],
        });
      } else {
        initDefaultValues();
      }
    }
  }, []);

  const handleSubmit = async (values, form, callback) => {
    if (submitting) return;

    const {
      viewer,
      projects,
      saveProject,
      saveExpertRequest,
      invalidateFetchedProjectsCache,
      notify,
      setAddress,
    } = props;

    if (!viewer.phone && values.phone) {
      try {
        setAddress(viewer.profile.id, 'phone', values.phone, true);
      } catch (err) {
        if (err.message.startsWith(ADDRESS_TAKEN_ERROR)) {
          callback({
            phone: 'Already in use, please use another',
          });
          return;
        }
        notify('Error saving phone number.', 'error');
      }
    }

    const {
      project_name: projectName,
      tracking_code: trackingCode,
      // eslint-disable-next-line camelcase
      project_add_new,
      ...expertRequestValues
    } = values;

    if (!expertRequestValues.name) {
      notify('Expert request must have a name.', 'error');
      return;
    }

    const groupId = values.group_id;
    let projectId = values.project_id;
    const projectEdges = (projects && projects.edges) || [];
    const shouldCreateProject =
      !projectId || !projectEdges.find((e) => e.node.id === projectId);

    setSubmitting(true);

    try {
      if (shouldCreateProject) {
        const createdProject = await saveProject({
          name: projectName || values.name,
          tracking_code: trackingCode,
          group_id: groupId,
        });
        projectId = createdProject.id;
        form.change('project_id', projectId);
        setCache(cacheValueKey, { ...values, project_id: projectId });
      }

      const expertRequest = await saveExpertRequest(
        {
          ...expertRequestValues,
          project_id: projectId,
        },
        {
          fetchProject: true,
        }
      );

      await invalidateFetchedProjectsCache();

      clearCache(cacheValueKey);
      navigate(`/expert_request/${expertRequest.id}/add_members`);
    } catch (err) {
      if (err.message.startsWith(PRIVATE_DISCLOSURE_ERROR)) {
        notify(
          'User is not authorized to choose private disclosure (premium).',
          'error'
        );
        return;
      }

      if (err.rawError) {
        const errors = Array.isArray(err.rawError)
          ? err.rawError
          : [err.rawError];
        errors.forEach((error) => {
          notify(error.message, 'error');
        });
        return;
      }

      notify('Error when creating expert request.', 'error');
      Promise.reject(err);
    } finally {
      setSubmitting(false);
    }
  };

  const onNext = () => {
    const { step, query } = props;
    const stepNumber = steps.indexOf(step);
    const nextUrl =
      steps[stepNumber + 1] &&
      `${baseUrl}/${steps[stepNumber + 1].path}${queryPart(query)}`;
    navigate(nextUrl);
  };

  const renderStep = (isMobileVersion, { form, handleSubmit }) => {
    const { step } = props;
    const { component } = step;

    return React.createElement(component, {
      onNext: onNext,
      isMobileVersion,
      change: form.change,
      values: form.getState().values,
      handleSubmit,
    });
  };

  return (
    <Layout
      {...LayoutParams}
      hideSearch
      showNewRequest={false}
      selected="expert_requests"
    >
      <Body style={{ paddingTop: 30, paddingBottom: 30 }}>
        <MediaQuery maxWidth={SCREEN_SM}>
          {(isMobileVersion) => (
            <Form
              onSubmit={handleSubmit}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators,
              }}
              validate={(values) =>
                step &&
                step.component &&
                step.component.validate &&
                step.component.validate(values, props)
              }
            >
              {(formRenderProps) => (
                <>
                  <ExpertRequestSpy
                    cacheKey={cacheValueKey}
                    formValuesInitialized={
                      initialValues.formInitialized && stepNumber > 1
                    }
                  />
                  {renderStep(isMobileVersion, formRenderProps)}
                </>
              )}
            </Form>
          )}
        </MediaQuery>
      </Body>
    </Layout>
  );
};

const ExpertRequestNewConnected = connect(
  (state) => ({
    viewer: state.viewer,
    projects: state.projects.names || [],
    groups: state.groups.all || [],
  }),
  {
    saveProject,
    invalidateFetchedProjectsCache,
    saveExpertRequest,
    fetchBaseExpertRequest,
    notify,
    setAddress,
    popup,
  }
)(ExpertRequestNew);

export const addMembers = {
  path: `/expert_request/:id/add_members`,
  element: <WrapperComponent />,
  async action({ store, params: { id } }) {
    const expertRequest = await store.dispatch(fetchExpertRequest(id));
    if (!expertRequest) return redirect('/404');

    document.title = 'Invite Members';
    return (
      <Layout hideSearch>
        <Body style={{ paddingTop: 30, paddingBottom: 30 }}>
          <AddMembers
            project={expertRequest.project}
            expertRequestId={expertRequest.id}
          />
        </Body>
      </Layout>
    );
  },
};
