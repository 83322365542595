import React from 'react';
import { Field } from 'react-final-form';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Checkbox } from '../../FormAdapters';
import Currency from '../../Currency/Currency';
import { darkGray, black } from '../../../core/colors';
import Button from '../../Button/Button';
import MuiAlert from '@mui/material/Alert';

const useStyles = makeStyles((theme) => ({
  divider: {
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    margin: '20px 0',
    [theme.breakpoints.down('md')]: {
      margin: '10px 0',
    },
  },
  footer: {
    marginTop: 30,
  },
  paragraph: {
    fontWeight: 500,
    marginTop: 5,
    marginBottom: 5,
  },
  subParagraph: {
    color: darkGray,
    marginTop: 10,
    marginBottom: 5,
  },
  label: {
    color: darkGray,
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      padding: '9px 0 ',
    },
  },
  labelChecked: {
    color: black,
  },
}));

function RateAndPreferenceForm({
  handleSubmit,
  showHourlyRate,
  showAvailableMarketplace,
  showProfilePublicity,
  submitError,
}) {
  const s = useStyles();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Grid container>
        {showHourlyRate && (
          <>
            <Grid item md={2} sm={4} xs={6}>
              <Field
                component={Currency}
                name="bill_rate"
                label="Hourly Rate"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Divider className={s.divider} />
            </Grid>
          </>
        )}

        <Grid item md={12} sm={12} xs={12}>
          <p className={s.paragraph}>Consultation Preferences</p>

          <Field
            type="checkbox"
            component={Checkbox}
            labelClasses={{ label: s.label }}
            labelCheckedClasses={{ label: s.labelChecked }}
            FormControlProps={{ margin: 'none' }}
            name="available_long_term"
            label="Available for long-term projects"
          />
        </Grid>

        {showAvailableMarketplace && (
          <>
            <Grid item xs={12}>
              <Divider className={s.divider} />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <p className={s.paragraph}>OnFrontiers Marketplace</p>

              <p className={s.subParagraph}>
                By opting in to join the OnFrontiers marketplace, our research
                managers will match you to participate in projects for our
                diverse set of global customers.
              </p>

              <Field
                type="checkbox"
                component={Checkbox}
                labelClasses={{ label: s.label }}
                labelCheckedClasses={{ label: s.labelChecked }}
                FormControlProps={{ margin: 'none' }}
                name="available_marketplace"
                label="Yes, add my profile to the OnFrontiers marketplace"
              />
            </Grid>
          </>
        )}

        {showProfilePublicity && (
          <>
            <Grid item xs={12}>
              <Divider className={s.divider} />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <p className={s.paragraph}>Search Engines</p>

              <p className={s.subParagraph}>
                Show my profile in results in popular search engines like
                Google, Bing, Yahoo, etc.
              </p>

              <Field
                type="checkbox"
                component={Checkbox}
                labelClasses={{ label: s.label }}
                labelCheckedClasses={{ label: s.labelChecked }}
                FormControlProps={{ margin: 'none' }}
                name="public_profile"
                label="Yes, make my profile public"
              />
            </Grid>
          </>
        )}
      </Grid>

      {submitError &&
        submitError.map((errorMessage) => (
          <MuiAlert severity="error">{errorMessage}</MuiAlert>
        ))}
      <div className={s.footer}>
        <Button size="large" type="submit" fullWidth={mobile}>
          Complete Profile
        </Button>
      </div>
    </form>
  );
}

export default RateAndPreferenceForm;
