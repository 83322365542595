import React from 'react';
import { connect } from 'react-redux';

import FAIcon from '../../components/Icon/FAIcon';
import { black } from '../../core/colors';
import s from './AddMembers.module.scss';
import EditMembersForm from '../../components/EditProjectMembers/Form';
import MediaQuery from '../../components/MediaQuery';
import { SCREEN_XS } from '../../constants';
import { sortMembers } from '../../core/project';
import { useNavigate } from 'react-router-dom';

const iconStyle = {
  width: 16,
  height: 16,
  fontSize: 16,
  margin: 16 * 0.2,
  color: black,
};

function AddMembers({ viewer, project, expertRequestId }) {
  const navigate = useNavigate();
  const projectMembers = project.members || [];
  const membersToEdit = sortMembers(
    projectMembers.filter((m) => m.role !== 'expert' && m.state === 'active')
  );

  return (
    <div className={s.root}>
      <div className={s.header}>
        <div className={s.headerIcon}>
          <FAIcon icon="binoculars" style={iconStyle} />
        </div>
      </div>
      <div className={s.header}>
        We’re working on finding your Expert matches.
      </div>
      <div className={s.subHeader}>
        In the meantime, invite your team members to your project.
      </div>
      <MediaQuery maxWidth={SCREEN_XS}>
        {(isMobileVersion) => (
          <EditMembersForm
            project={project}
            initialValues={{ members: membersToEdit }}
            onReset={() => navigate(`/expert_request/${expertRequestId}`)}
            onSubmit={() => navigate(`/expert_request/${expertRequestId}`)}
            usersLocked={[viewer.id]}
            buttonSize={isMobileVersion ? 'small' : 'medium'}
          />
        )}
      </MediaQuery>
    </div>
  );
}

const Component = connect((state) => ({
  viewer: state.viewer,
}))(AddMembers);
export default Component;
