import moment from 'moment-timezone';
import ActionTypes from '../core/ActionTypes';
import { shouldResetCollection } from '../core/util';

const {
  EXPERT_REQUEST__LIST,
  EXPERT_REQUEST__UPDATE,
  EXPERT_REQUEST__DELETE,
  EXPERT_REQUEST__REMOVE_CANDIDATE,
  EXPERT_REQUEST__ADD_CANDIDATE,
  EXPERT_REQUEST__FETCHED_INIT_CANDIDATES,
  EXPERT_REQUEST__FETCHED_MORE_CANDIDATES,
  EXPERT_REQUEST__FETCHING_CANDIDATES,
  EXPERT_REQUEST__UPDATE_CANDIDATE,
} = ActionTypes;

export const erTypes = Object.freeze({
  consultation: 'consultation',
  newHire: 'new_hire',
  consultingProject: 'consulting_project',
  writtenReview: 'written_review',
  proBonoConsultation: 'pro_bono_consultation', // legacy
});

export const erTypeLabels = Object.freeze({
  [erTypes.consultation]: 'Consultation',
  [erTypes.newHire]: 'New Hire',
  [erTypes.consultingProject]: 'Consulting Project',
  [erTypes.writtenReview]: 'Written Review',
  [erTypes.proBonoConsultation]: 'Pro Bono', // legacy
});

export const isSupportedType = (type) => Object.values(erTypes).includes(type);

export const isWorkType = (type) =>
  [erTypes.newHire, erTypes.consultingProject].includes(type);

export const isUnpaidType = (type) =>
  [erTypes.newHire, erTypes.consultingProject].includes(type);

export const isAttachmentDeliverableType = (type) =>
  [erTypes.writtenReview].includes(type);

export const isOpportunityType = (type) =>
  [erTypes.consultingProject, erTypes.newHire].includes(type);

export const isCallType = (type) =>
  [erTypes.consultation, erTypes.consultingProject, erTypes.newHire].includes(
    type
  );

export const isFixedRate = (type) => {
  return [
    erTypes.consultingProject,
    erTypes.newHire,
    erTypes.writtenReview,
  ].includes(type);
};

export const isSetExpectedDurationType = (type) =>
  [erTypes.writtenReview].includes(type);

export const candidateCategories = {
  matched: [
    'polishing',
    'vetting',
    'verified',
    'matched',
    'rejected_by_client',
    'rejected_by_research',
  ],
  suggested: [
    'suggested_by_platform',
    'suggested_by_research',
    'contacted',
    'interested',
    'rejected_suggestion',
  ],
};

const projectField = `project {
  id
  name
  html_url
  tracking_code
  group { id name }
  members {
    id
    email
    role
    state
    user {
      id
      name
      first_name
      last_name
      html_url
      picture_url
    }
  }
  permissions
}`;

const candidateFields = `
  id
  email
  state
  last_state_change
  show_engagement_agreement
  match_note {
    author {
      id
      first_name
      last_name
      name
      picture_url
      html_url
    }
    note
    recommended
  }
  client_note {
    author {
      id
      first_name
      last_name
      name
      picture_url
      html_url
    }
    note
    is_good_match
  }
  match_score
  match_experience
  bill_rate
  credit_rate
  qualification_responses {
    id
    query { id query response_type}
    can_answer
    text_response
  }
  question_answers {
    id
    query { id query response_type}
    can_answer
    text_response
  }
  updated_by {
    id
    name
  }
  created_by {
    id
    name
    picture_url
    html_url
  }
  profile {
    user {
      id
      username
    }
    id
    credit_rate
    expert_state
    first_name
    last_name
    name
    html_url
    linkedin_url
    title
    summary
    timezone
    city
    country
    picture_url
    available_marketplace
    can_request_consultation(expert_request_id: $expertRequestId)
    emails {
      address
    }
    keywords
    group_keywords {
      group { id, name }
      keywords { id, name }
    }
    experiences {
      title
      organization
    }
    expert_internal_networks {
      id
      network {
        id
        name
        group { id }
      }
    }
  }
`;

export function inviteExpert(expertRequestId, email) {
  return (dispatch, getState, { graphql }) =>
    graphql.mutate(
      `
    ($expertRequestId: String!, $email: String!) {
      inviteExpertToExpertRequest(
        expert_request_id: $expertRequestId
        email: $email
      )
    }
  `,
      { expertRequestId, email }
    );
}

export function saveExpertRequest(
  expertRequest,
  {
    fetchProject = false,
    includeQueries = true,
    includeAttachments = true,
    includeAdminFields = true,
  } = {}
) {
  return async (dispatch, getState, { graphql }) => {
    const isUpdate = expertRequest.id;
    const mutation = isUpdate ? 'updateExpertRequest' : 'createExpertRequest';

    const data = {
      id: expertRequest.id,
      name: expertRequest.name,
      description: expertRequest.description?.trim(),
      disclosure: expertRequest.disclosure,
      focus_areas: expertRequest.focus_areas,
      project_id: expertRequest.project_id,
      phone: expertRequest.phone,
      companies_avoid: expertRequest.companies_avoid.filter(
        (x) => x && x.trim()
      ),
      companies_pursue: expertRequest.companies_pursue.filter((x) => x?.trim()),
      regions: expertRequest.regions.map((x) => x.id),
      sectors: expertRequest.sectors.map((x) => x.id),
      er_type: expertRequest.er_type,
      instructions_research: expertRequest.instructions_research?.trim(),
      group_about: expertRequest.group_about?.trim(),
      job_scope: expertRequest.job_scope?.trim(),
      expected_duration: expertRequest.expected_duration,
      opportunity_location: expertRequest.opportunity_location,
    };

    if (includeAdminFields) {
      data.tags = expertRequest.tags;
      data.time_done_scoping_call = expertRequest.time_done_scoping_call
        ? expertRequest.time_done_scoping_call
        : '0001-01-01T00:00:00Z';
    }

    // they share same permission
    if (includeQueries) {
      data.qualifications = expertRequest.qualifications?.filter((q) =>
        q?.query?.trim()
      );
      data.questions = expertRequest.questions?.filter((q) => q?.query?.trim());
    }

    const requiredStringOnCreate = expertRequest.id ? 'String' : 'String!';

    const result = await graphql.mutate(
      `
      (
        ${isUpdate ? '$id: String!' : ''}
        ${'name' in data ? '$name: String!' : ''}
        ${'description' in data ? '$description: String' : ''}
        ${'disclosure' in data ? '$disclosure: DisclosureOption' : ''}
        ${'focus_areas' in data ? '$focus_areas: [String]' : ''}
        ${'companies_pursue' in data ? '$companies_pursue: [String]' : ''}
        ${'companies_avoid' in data ? '$companies_avoid: [String]' : ''}
        ${
          'qualifications' in data
            ? '$qualifications: [ExpertRequestQueryInput]'
            : ''
        }
        ${'questions' in data ? '$questions: [ExpertRequestQueryInput]' : ''}
        ${'regions' in data ? '$regions: [String]' : ''}
        ${'sectors' in data ? '$sectors: [String]' : ''}
        ${'tags' in data ? '$tags: [String]' : ''}
        ${'expected_duration' in data ? '$expected_duration: Duration' : ''}
        ${'project_id' in data ? `$project_id: ${requiredStringOnCreate}` : ''}
        ${'phone' in data ? '$phone: String' : ''}
        ${'er_type' in data ? '$er_type: ExpertRequestType!' : ''}
        ${
          'instructions_research' in data
            ? '$instructions_research: String'
            : ''
        }
        ${'group_about' in data ? '$group_about: String' : ''}
        ${'job_scope' in data ? '$job_scope: String' : ''}
        ${'opportunity_location' in data ? '$opportunity_location: String' : ''}
        ${
          'time_done_scoping_call' in data
            ? '$time_done_scoping_call: Datetime'
            : ''
        }
      ) {
        ${mutation} (
          ${isUpdate ? 'id: $id' : ''}
          ${'name' in data ? 'name: $name' : ''}
          ${'description' in data ? 'description: $description' : ''}
          ${'disclosure' in data ? 'disclosure: $disclosure' : ''}
          ${'focus_areas' in data ? 'focus_areas: $focus_areas' : ''}
          ${
            'companies_pursue' in data
              ? 'companies_pursue: $companies_pursue'
              : ''
          }
          ${
            'companies_avoid' in data ? 'companies_avoid: $companies_avoid' : ''
          }
          ${'qualifications' in data ? 'qualifications: $qualifications' : ''}
          ${'questions' in data ? 'questions: $questions' : ''}
          ${'regions' in data ? 'regions: $regions' : ''}
          ${'sectors' in data ? 'sectors: $sectors' : ''}
          ${'tags' in data ? 'tags: $tags' : ''}
          ${
            'expected_duration' in data
              ? 'expected_duration: $expected_duration'
              : ''
          }
          ${'project_id' in data ? 'project_id: $project_id' : ''}
          ${'phone' in data ? 'phone: $phone' : ''}
          ${'er_type' in data ? 'er_type: $er_type' : ''}
          ${
            'instructions_research' in data
              ? 'instructions_research: $instructions_research'
              : ''
          }
          ${'group_about' in data ? 'group_about: $group_about' : ''}
          ${'job_scope' in data ? 'job_scope: $job_scope' : ''}
          ${
            'opportunity_location' in data
              ? 'opportunity_location: $opportunity_location'
              : ''
          }
          ${
            'time_done_scoping_call' in data
              ? 'time_done_scoping_call: $time_done_scoping_call'
              : ''
          }
        ) {
          id
          qualifications { id, query, response_type, required }
          questions { id, query, response_type, required }
          ${fetchProject ? projectField : ''}
          permissions
        }
      }`,
      data
    );

    const updatedExpertRequest = { ...expertRequest, ...result[mutation] };

    if (includeAttachments) {
      const attachments = expertRequest.attachments || [];
      const newAttachments = await dispatch(
        updateAttachments(
          updatedExpertRequest.id,
          attachments
            .filter((a) => !('id' in a))
            .map((a) => {
              return {
                name: a.name,
                description: a.description,
                file_url: a.file_url,
                hide_from_experts: a.hide_from_experts,
              };
            })
        )
      );
      const existingAttachments = attachments.filter((a) => 'id' in a);
      updatedExpertRequest.attachments = [
        ...existingAttachments,
        ...newAttachments,
      ];
    }

    dispatch({
      type: EXPERT_REQUEST__UPDATE,
      expertRequest: updatedExpertRequest,
    });

    if (isUpdate) {
      dispatch(
        fetchExpertRequestCandidates(updatedExpertRequest.id, 'suggested', true)
      );
    }

    return updatedExpertRequest;
  };
}

function updateAttachments(expertRequestId, attachments) {
  return async (dispatch, getState, { graphql }) => {
    const newAttachments = attachments
      .filter((a) => !('id' in a))
      .map((a) => {
        return {
          name: a.name,
          description: a.description,
          file_url: a.file_url,
          hide_from_experts: a.hide_from_experts,
        };
      });
    const results = await graphql.mutate(
      `
      (
        $expertRequestId: String!
        $attachments: [EngagementAttachmentInput]!
      ) {
        requestAddAttachments(
          expert_request_id: $expertRequestId
          attachments: $attachments
        ) {
            id
            created_at
            author {
              name
              html_url
            }
            expert_request_id
            consultation_id
            name
            description
            file_url
            hide_from_experts
        }
      }`,
      {
        expertRequestId,
        attachments: newAttachments,
      }
    );
    return results.requestAddAttachments;
  };
}

export function deleteExpertRequest(id) {
  return async (dispatch, getState, { graphql }) => {
    const data = await graphql.mutate(
      `($id: String!) {
      deleteExpertRequest(id: $id) {
        id
        project { id }
      }
    }`,
      { id }
    );

    if (data && data.deleteExpertRequest) {
      const {
        project: { id: projectId },
      } = data.deleteExpertRequest;
      dispatch({
        type: EXPERT_REQUEST__DELETE,
        projectId,
        id,
      });
    }

    return data;
  };
}

export function updateExpertRequestState(id, state, closeReason) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.mutate(
      `
      ($id: String!, $state: ExpertRequestState!, $close_reason: String) {
        updateExpertRequestState(id: $id, state: $state, close_reason: $close_reason) {
          id
          state
          project { id }
          close_reason
        }
      }
    `,
      { id, state, close_reason: closeReason }
    );

    if (result.updateExpertRequestState) {
      const {
        id,
        state,
        project: { id: projectId },
      } = result.updateExpertRequestState;
      dispatch({
        type: EXPERT_REQUEST__UPDATE,
        projectId,
        expertRequest: { id, state },
      });
    }
  };
}

export function fetchBaseExpertRequest(id) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(
      `query getExpertRequest($id: String!) {
      expertRequest(id: $id) {
        id
        name
        er_type
        description
        focus_areas
        qualifications { id, query, response_type, required }
        questions { id, query, response_type, required }
        attachments {
            id
            created_at
            author {
              name
              html_url
            }
            expert_request_id
            consultation_id
            name
            description
            file_url
            hide_from_experts
        }
        companies_pursue
        companies_avoid
        disclosure
        regions {
          id
          name
        }
        sectors {
          id
          name
        }
        tags
        expected_duration
        project { id }
        phone
      }
    }`,
      { id }
    );

    return result.expertRequest;
  };
}

export function fetchExpertRequest(id) {
  return async (dispatch, getState, { graphql }) => {
    for (const ger of getState().expertRequests.default.edges || []) {
      if (ger.node.id !== id) continue;
      if (!ger.expiresAt || moment().isSameOrAfter(ger.expiresAt)) continue;
      return ger.node;
    }

    const result = await graphql.query(
      `query getExpertRequest($expertRequestId: String!) {
      expertRequest(id: $expertRequestId) {
        id
        html_url
        created_at
        name
        description
        focus_areas
        qualifications { id, query, response_type, required }
        questions { id, query, response_type, required }
        attachments {
            id
            created_at
            author {
              name
              html_url
            }
            expert_request_id
            consultation_id
            name
            description
            file_url
            hide_from_experts
        }
        companies_pursue
        companies_avoid
        disclosure
        state
        archived
        slug
        public_html_url
        creator {
          id
          name
          first_name
          last_name
          html_url
          picture_url
        }
        regions {
          id
          name
        }
        sectors {
          id
          name
        }
        er_type
        group_about
        instructions_research
        job_scope
        opportunity_location
        ${projectField}
        permissions
        consultations {
          id
          html_url
          starts_at
          started_at
          ended_at
          created_at
          state
          proposed_times
          requester_name
          expert_name
          requester {
            id
            name
            html_url
            picture_url
          }
          expert {
            id
            name
            html_url
            picture_url
          }
        }
        tags
        expected_duration
        phone
        stats {
          calls
        }
        time_done_scoping_call
      }
    }`,
      { expertRequestId: id }
    );

    const { expertRequest } = result;

    if (expertRequest) {
      dispatch({
        type: EXPERT_REQUEST__UPDATE,
        expertRequest,
      });
      return expertRequest;
    }
  };
}

export function fetchExpertRequestCandidates(
  id,
  type = 'matched' | 'suggested',
  reset,
  cursor = undefined,
  pageSize = 10
) {
  const states = candidateCategories[type];
  return async (dispatch, getState, { graphql }) => {
    dispatch({
      type: EXPERT_REQUEST__FETCHING_CANDIDATES,
      expertRequestId: id,
    });
    const result = await graphql.query(
      `
      query getExpertRequestCandidates(
        $expertRequestId: String!
        $states: [String]
        $first: Int!
        $after: String
      ) {
        expertRequestCandidates(
          id: $expertRequestId
          states: $states
          first: $first
          after: $after
        ) {
          pageInfo {
            hasNextPage
            metaData
          }
          edges {
            cursor
            node {
              ${candidateFields}
            }
          }
        }
      }`,
      {
        expertRequestId: id,
        states,
        first: pageSize,
        after: cursor,
      }
    );
    const { expertRequestCandidates } = result;

    dispatch({
      type: reset
        ? EXPERT_REQUEST__FETCHED_INIT_CANDIDATES
        : EXPERT_REQUEST__FETCHED_MORE_CANDIDATES,
      expertRequestId: id,
      candidates: expertRequestCandidates,
      candidateType: type,
    });
  };
}

export function fetchPublicExpertRequest(id) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(
      `query getExpertRequest($id: String!) {
      expertRequest(id: $id) {
        id
        html_url
        created_at
        name
        description
        focus_areas
        qualifications { id, query, response_type, required }
        questions { id, query, response_type, required }
        state
        slug
        public_html_url
        regions {
          id
          name
        }
        sectors {
          id
          name
        }
        project {
          group {
            name
          }
        }
        public_group_name
        group_about
        er_type
        job_scope
        opportunity_location
        unpaid
        disclosure
      }
    }`,
      { id }
    );

    const { expertRequest } = result;

    if (expertRequest) {
      dispatch({
        type: EXPERT_REQUEST__UPDATE,
        expertRequest,
      });
      return expertRequest;
    }
  };
}

export function fetchExpertRequests({
  groupId,
  memberOnly,
  withGroupOnly = true,
  state,
  collection = state || 'all',
} = {}) {
  return async (dispatch, getState, { graphql }) => {
    const expertRequests = getState().expertRequests[collection];
    if (!shouldResetCollection(expertRequests, 1000)) return expertRequests;

    const { viewer } = getState();

    if (memberOnly === undefined) {
      memberOnly = !viewer.admin;
    }

    const data = await graphql.query(
      `query fetchExpertRequests(
        $memberOnly: Boolean
        $state: ExpertRequestState
        $groupId: String
        $withGroupOnly: Boolean
      ) {
        expertRequests(
          first: 200
          member_only: $memberOnly
          with_group_only: $withGroupOnly
          state: $state
          group_id: $groupId
        ) {
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              html_url
              name
              state
              disclosure
              er_type
              project {
                group {
                  id
                  name
                  account_type
                }
              }
            }
          }
        }
      }`,
      {
        groupId,
        memberOnly,
        withGroupOnly,
        state,
      }
    );

    const page = data.expertRequests;

    dispatch({
      type: EXPERT_REQUEST__LIST,
      collection,
      reset: true,
      ...page,
    });

    return page;
  };
}

export function fetchExpertRequestMembers(id) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(
      `query getExpertRequest($id: String!) {
      expertRequest(id: $id) {
        id
        er_type
        name
        permissions
        disclosure
        project {
          tracking_code
          group {
            id
            name
          }
          members {
            id
            role
            state
            user {
              id
              username
              name
            }
          }
        }
      }
    }`,
      { id }
    );

    const { expertRequest } = result;

    if (expertRequest) {
      dispatch({
        type: EXPERT_REQUEST__UPDATE,
        expertRequest,
      });
    }
    return expertRequest;
  };
}

export function fetchProfileCandidates(id) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(
      `query profileCandidates($id: String!) {
      profileCandidates(id: $id) {
        id
        request_id
      }
    }`,
      { id }
    );

    return result.profileCandidates;
  };
}

export function fetchCandidate(expertRequestId, profileId) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(
      `query profileCandidate(
        $expertRequestId: String!
        $profileId: String!
      ) {
      profileCandidate(
        profile_id: $profileId
        expert_request_id: $expertRequestId
      ) {
        credit_rate
        show_engagement_agreement
      }
    }`,
      { expertRequestId, profileId }
    );

    return result.profileCandidate;
  };
}

export function addExpertRequestCandidates({
  expertRequestId,
  dryRun = true,
  sendInvitationEmail,
  invitationEmailSubject,
  invitationEmailBody,
  profileIds,
  state,
}) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.mutate(
      `(
      $expertRequestId: String!
      $dryRun: Boolean
      $sendInvitationEmail: Boolean
      ${invitationEmailSubject ? '$invitationEmailSubject: String' : ''}
      ${invitationEmailBody ? '$invitationEmailBody: String' : ''}
      $profileIds: [String!]
      $state: CandidateState!
    ) {
      addExpertRequestCandidates(
        expert_request_id: $expertRequestId
        dry_run: $dryRun
        send_invitation_email: $sendInvitationEmail
        ${
          invitationEmailSubject
            ? 'invitation_email_subject: $invitationEmailSubject'
            : ''
        }
        ${
          invitationEmailBody
            ? 'invitation_email_body: $invitationEmailBody'
            : ''
        }
        profile_ids: $profileIds
        state: $state
      ) {
        success
        results {
          profile_id
          error_code
          warning_code
          ${
            dryRun
              ? ''
              : `
            candidate {
              ${candidateFields}
            }
          `
          }
        }
      }
    }`,
      {
        expertRequestId,
        dryRun,
        sendInvitationEmail,
        invitationEmailSubject,
        invitationEmailBody,
        profileIds,
        state,
      }
    );

    const data = result.addExpertRequestCandidates;

    if (!dryRun && data && data.success) {
      dispatch({
        type: EXPERT_REQUEST__ADD_CANDIDATE,
        expertRequestId,
        candidates: data.results.map((r) => r.candidate),
      });
    }

    return data;
  };
}

export function requestAddExpertRequestCandidate(candidate) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.mutate(
      `(
      $expert_request_id: String!
      $match_experience: String
      ${
        candidate.question_answers
          ? '$question_answers: [CandidateQueryResponseInput]'
          : ''
      }
      ${
        candidate.qualification_responses
          ? '$qualification_responses: [CandidateQueryResponseInput]'
          : ''
      }
    ) {
      requestAddExpertRequestCandidate(
        expert_request_id: $expert_request_id
        match_experience: $match_experience
        ${
          candidate.question_answers
            ? 'question_answers: $question_answers'
            : ''
        }
        ${
          candidate.qualification_responses
            ? 'qualification_responses: $qualification_responses'
            : ''
        }
      ) {
        id
        email
      }
    }`,
      candidate
    );

    return result.requestAddExpertRequestCandidate;
  };
}

export function updateExpertRequestCandidate(expertRequestId, candidate) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.mutate(
      `(
      $id: String!
      $state: CandidateState
      $match_experience: String
      $match_note: CandidateMatchNoteInput
      $client_note: CandidateClientNoteInput
      $qualification_responses: [CandidateQueryResponseInput]
      $question_answers: [CandidateQueryResponseInput]
      ${candidate.note ? '$note: String' : ''}
      ${candidate.priority ? '$priority: Boolean' : ''}
      $bill_rate: Int
      $credit_rate: Int
      $show_engagement_agreement: Boolean
    ) {
      updateExpertRequestCandidate(
        id: $id
        state: $state
        match_note: $match_note
        client_note: $client_note
        match_experience: $match_experience
        qualification_responses: $qualification_responses
        question_answers: $question_answers
        ${candidate.note ? 'update_note: $note' : ''}
        ${candidate.priority ? 'priority: $priority' : ''}
        bill_rate: $bill_rate
        credit_rate: $credit_rate
        show_engagement_agreement: $show_engagement_agreement
      ) {
        id
        email
        state
        last_state_change
        match_note {
          author {
            id
            first_name
            last_name
            name
            picture_url
            html_url
          }
          note
          recommended
        }
        client_note {
          author {
            id
            first_name
            last_name
            name
            picture_url
            html_url
          }
          note
          is_good_match
        }
        updated_by {
          id
          name
        }
        qualification_responses {
          id
          can_answer
          text_response
          query { id query response_type}
        }
        question_answers {
          id
          can_answer
          text_response
          query { id query response_type}
        }
        match_experience
        bill_rate
        credit_rate
        last_state_change
        show_engagement_agreement
      }
    }`,
      candidate
    );

    const updated = result.updateExpertRequestCandidate;

    dispatch({
      type: EXPERT_REQUEST__UPDATE_CANDIDATE,
      expertRequestId,
      candidate: {
        ...candidate,
        ...updated,
      },
    });
  };
}

export function removeExpertRequestCandidate(expertRequestId, candidate) {
  return (dispatch, getState, { graphql }) =>
    graphql
      .mutate(
        `(
    $id: String!
  ) {
    removeExpertRequestCandidate(id: $id) {
      id
      email
    }
  }`,
        { id: candidate.id }
      )
      .then(() =>
        dispatch({
          type: EXPERT_REQUEST__REMOVE_CANDIDATE,
          expertRequestId,
          candidate,
        })
      );
}
