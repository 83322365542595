import React, { PureComponent, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { reduxForm, Field, FieldArray } from 'redux-form';
import moment from 'moment-timezone';
import cx from 'classnames';

import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Checkbox as FormAdapterCheckbox,
  TextField,
} from '../../components/FormAdapters';
import Divider from '../../components/Divider';
import EditIcon from '../../components/EditIcon';
import Button from '../../components/Button/Button';
import Image from '../../components/Image';
import EditDialog from '../../components/EditDialog';
import EditableList from '../../components/EditableList';
import MonthPicker from '../../components/MonthPicker';
import YearPicker from '../../components/YearPicker';
import KeywordInput from '../../components/KeywordInput';
import PhoneInput from '../../components/PhoneInput';
import SelectTimezone from '../../components/SelectTimezone';
import SelectLocation from '../../components/SelectLocation';
import MaterialIcon from '../../components/Icon/MaterialIcon';
import Dialog from '../../components/Dialog/Dialog';
import SelectSector from '../../components/SelectSector';
import FAIcon from '../../components/Icon/FAIcon';
import { mergeProfiles } from '../../actions/profile';
import { notify } from '../../actions/ui';
import { presignedFilestackURL } from '../../actions/filestack';
import history from '../../core/history';
import { getSameOriginPath, parseId } from '../../core/util';
import {
  compareEducations,
  compareExperiences,
  equalEducation,
  equalExperience,
  experienceDateToString,
  experienceStringToDate,
  formatDate,
  listEquals,
} from './helper';
import { black, darkGray, darkGreen, red500 } from '../../core/colors';
import { formatExperiencePeriod } from '../../core/profile';
import s from './ProfileMerge.module.scss';
import { useNavigate } from 'react-router-dom';

const EXISTING = 'existing';
const CONFLICTING = 'conflicting';

function ProfileMerge(props) {
  const navigate = useNavigate();
  const [conflicts, setConflicts] = useState({});
  const [editing, setEditing] = useState(null);
  const [saveConfirmationOpen, setSaveConfirmationOpen] = useState(false);

  const {
    existing,
    groups,
    conflicting,
    mergeProfiles,
    notify,
    expertRequests,
  } = props;

  useEffect(() => {
    addSimpleFieldConflict('raw_picture_url', {
      title: 'Profile Photo',
      editDialog: false,
      viewComponent: ProfilePicture,
    });

    addSimpleFieldConflict('available_marketplace', {
      title: 'Available Marketplace',
      editDialog: false,
      viewComponent: AvailableMarketplace,
    });

    addSimpleFieldConflict('first_name', {
      title: 'First Name',
    });

    addSimpleFieldConflict('last_name', {
      title: 'Last Name',
    });

    addFieldsConflict('headline', ['title', 'summary'], {
      title: 'Headline & Bio',
      viewComponent: HeadlineAndBio,
      editComponent: EditHeadlineAndBio,
    });

    addFieldsConflict('location', ['country', 'city'], {
      title: 'Location',
      viewComponent: CountryAndCity,
      editComponent: EditCountryAndCity,
    });

    addSimpleFieldConflict('internal_cv_url', {
      title: 'Attached CV',
      editDialog: false,
      link: true,
    });

    addFieldsConflict('linkedin', ['linkedin_url', 'linkedin_username'], {
      title: 'LinkedIn',
      viewComponent: LinkedIn,
      editComponent: EditLinkedIn,
    });

    addSimpleFieldConflict('skype', {
      title: 'Skype',
    });

    addSimpleFieldConflict('timezone', {
      title: 'Time Zone',
      editComponent: EditTimezone,
    });

    if (!props.existing.user) {
      addListFieldConflict('emails', {
        title: 'Email Addresses',
        viewComponent: List,
        editComponent: EditEmails,
        valueMapper: (emails) => emails && emails.map((e) => e.display),
      });

      addListFieldConflict('phones', {
        title: 'Phones',
        viewComponent: List,
        editComponent: EditPhones,
        valueMapper: (phones) => phones && phones.map((p) => p.display),
      });
    }

    addListFieldConflict('sector_ids', {
      title: 'Sectors',
      viewComponent: ListSectors,
      editComponent: EditSectors,
    });

    addListFieldConflict('region_ids', {
      title: 'Regions',
      viewComponent: ListRegions,
      editComponent: EditRegions,
    });

    addListFieldConflict('keywords', {
      title: 'Keywords',
      viewComponent: List,
      editComponent: EditKeywords,
    });

    addNetworksConflict();

    addExpertRequestsConflict();

    addExperiencesConflict();

    addEducationConflict();
  }, []);

  const addSimpleFieldConflict = (field, opts) =>
    addFieldsConflict(field, [field], opts);

  const addFieldsConflict = useCallback(
    (section, fields, opts) => {
      if (
        !opts.conflictsWithEmpty &&
        fields.every((f) => conflicting[f] === '' || conflicting[f] === null)
      )
        return;

      if (fields.every((f) => existing[f] === conflicting[f])) return;

      const existingValues = {};
      const conflictingValues = {};
      fields.forEach((f) => {
        existingValues[f] = existing[f];
        conflictingValues[f] = conflicting[f];
      });

      setConflicts((conflicts) => ({
        ...conflicts,
        [section]: {
          merge: 'exclusive',
          editDialog: true,
          ...opts,
          existing: {
            selected: false,
            value: existingValues,
          },
          conflicting: {
            selected: true,
            value: conflictingValues,
          },
        },
      }));
    },
    [existing, conflicting]
  );

  const addListFieldConflict = useCallback(
    (field, opts) => {
      if (
        !opts.conflictsWithEmpty &&
        (!conflicting[field] || conflicting[field].length === 0)
      )
        return;
      const valueMapper = opts.valueMapper || ((x) => x);

      const existingField = valueMapper(existing[field]);
      const conflictingField = valueMapper(conflicting[field]);

      if (listEquals(existingField, conflictingField)) return;

      setConflicts((conflicts) => ({
        ...conflicts,
        [field]: {
          merge: 'inclusive',
          editDialog: true,
          ...opts,
          existing: {
            selected: true,
            value: { [field]: existingField },
          },
          conflicting: {
            selected: true,
            value: { [field]: conflictingField },
          },
        },
      }));
    },
    [existing, conflicting, conflicts]
  );

  const addExperiencesConflict = useCallback(() => {
    existing.experiences = existing.experiences || [];
    conflicting.experiences = conflicting.experiences || [];

    const existingExperiences = [];
    const newExperiences = [];

    // Put all experiences in a single array so they can be sorted
    const allExperiences = [
      ...existing.experiences,
      ...conflicting.experiences,
    ].sort(compareExperiences);

    let i = 0;
    let showExperiences = false;

    allExperiences.forEach((e) => {
      const isExisting = existing.experiences.includes(e);
      const isNew = conflicting.experiences.includes(e);

      // Experience has already been added as a conflict of another experience,
      // let's skip.
      if (isExisting && existingExperiences.some((x) => x && x.value === e))
        return;
      if (isNew && newExperiences.some((x) => x && x.value === e)) return;

      const conflictFn = (cExp) =>
        e.organization === cExp.organization &&
        e.title === cExp.title &&
        formatDate(e.start_date) === formatDate(cExp.start_date);

      if (isNew) {
        // Conflicting side is selected by default
        newExperiences[i] = { value: e, selected: true };
        // New experience conflicts with existing experience
        const conflict = existing.experiences.find(conflictFn);
        if (conflict) {
          existingExperiences[i] = { value: conflict, selected: false };
          // If experience is exactly the same, no need to show the Experiences section
          if (!equalExperience(e, conflict)) showExperiences = true;
        } else {
          // No conflict, the other side is empty
          existingExperiences[i] = undefined;
          // Existing and New are not the same, let's show the Experience section
          showExperiences = true;
        }
      } else {
        // Existing side is selected by default unless there is a conflict
        existingExperiences[i] = { value: e, selected: true };
        // Existing experience conflicts with new experience
        const conflict = conflicting.experiences.find(conflictFn);
        if (conflict) {
          newExperiences[i] = { value: conflict, selected: true };
          existingExperiences[i].selected = false;
          if (!equalExperience(e, conflict)) showExperiences = true;
        } else {
          newExperiences[i] = undefined;
          showExperiences = true;
        }
      }
      i++;
    });

    if (showExperiences) {
      setConflicts((conflicts) => ({
        ...conflicts,
        experiences: {
          title: 'Experiences',
          existing: existingExperiences,
          conflicting: newExperiences,
          merge: 'exclusive',
          editDialog: true,
          viewComponent: Experience,
          editComponent: EditExperience,
        },
      }));
    }
  }, [existing, conflicting, conflicts]);

  const addEducationConflict = useCallback(() => {
    existing.education = existing.education || [];
    conflicting.education = conflicting.education || [];

    const existingEducation = [];
    const newEducation = [];

    // Put all education in a single array so they can be sorted
    const allEducation = [...existing.education, ...conflicting.education].sort(
      compareEducations
    );

    let i = 0;
    let showEducation = false;

    allEducation.forEach((e) => {
      const isExisting = existing.education.includes(e);
      const isNew = conflicting.education.includes(e);

      // Education has already been added as a conflict of another education,
      // let's skip.
      if (isExisting && existingEducation.some((x) => x && x.value === e))
        return;
      if (isNew && newEducation.some((x) => x && x.value === e)) return;

      const conflictFn = (cExp) =>
        e.school === cExp.school &&
        e.degree === cExp.degree &&
        formatDate(e.start_date) === formatDate(cExp.start_date);

      if (isNew) {
        // Conflicting side is selected by default
        newEducation[i] = { value: e, selected: true };
        // New education conflicts with existing education
        const conflict = existing.education.find(conflictFn);
        if (conflict) {
          existingEducation[i] = { value: conflict, selected: false };
          // If education is exactly the same, no need to show the Education section
          if (!equalEducation(e, conflict)) showEducation = true;
        } else {
          // No conflict, the other side is empty
          existingEducation[i] = undefined;
          // Existing and New are not the same, let's show the Education section
          showEducation = true;
        }
      } else {
        // Existing side is selected by default unless there is a conflict
        existingEducation[i] = { value: e, selected: true };
        // Existing education conflicts with new education
        const conflict = conflicting.education.find(conflictFn);
        if (conflict) {
          newEducation[i] = { value: conflict, selected: true };
          existingEducation[i].selected = false;
          if (!equalEducation(e, conflict)) showEducation = true;
        } else {
          newEducation[i] = undefined;
          showEducation = true;
        }
      }
      i++;
    });

    if (showEducation) {
      setConflicts((conflicts) => ({
        ...conflicts,
        education: {
          title: 'Education',
          existing: existingEducation,
          conflicting: newEducation,
          merge: 'exclusive',
          editDialog: true,
          viewComponent: Education,
          editComponent: EditEducation,
        },
      }));
    }
  }, [existing, conflicting, conflicts]);

  const extractExpertRequestCandidates = useCallback(
    (profile) => {
      const requests = profile.expert_request_candidates || [];

      if (profile.expert_request_id) {
        requests.push({
          request_id: profile.expert_request_id,
        });
      }

      return requests
        .map((req) => ({
          ...req,
          expert_request: expertRequests.find((er) => er.id === req.request_id),
        }))
        .filter((req) => !!req.expert_request);
    },
    [expertRequests]
  );

  const addExpertRequestsConflict = useCallback(() => {
    existing.requests = extractExpertRequestCandidates(existing);
    conflicting.requests = extractExpertRequestCandidates(conflicting);

    const existingRequests = [];
    const newRequests = [];

    // Put all networks in a single array so they can be sorted
    const allRequests = [...existing.requests, ...conflicting.requests];

    let i = 0;
    let showRequests = false;

    allRequests.forEach((request) => {
      const conflictFn = (cReq) => request.request_id === cReq.request_id;
      const existFn = (x) =>
        x && x.value && request.request_id === x.value.request_id;

      const isExisting = existing.requests.some(conflictFn);
      const isNew = conflicting.requests.some(conflictFn);

      // Network has already been added as a conflict of another request,
      // let's skip.
      if (isExisting && existingRequests.some(existFn)) return;
      if (isNew && newRequests.some(existFn)) return;

      if (isNew) {
        // Conflicting side is selected by default
        const conflict = conflicting.requests.find(conflictFn);
        newRequests[i] = { value: conflict, selected: true };
        // New request conflicts with existing request
        const current = existing.requests.find(conflictFn);
        if (current && current.id) {
          existingRequests[i] = { value: current, selected: false };
        } else {
          // No conflict, the other side is empty
          existingRequests[i] = undefined;
          // Existing and New are not the same, let's show the Networks section
          showRequests = true;
        }
      } else {
        // Existing side is selected by default unless there is a conflict
        existingRequests[i] = { value: request, selected: true };
        // Existing request conflicts with new request
        const conflict = conflicting.requests.find(conflictFn);
        if (conflict) {
          newRequests[i] = { value: conflict, selected: true };
          existingRequests[i].selected = false;
        } else {
          newRequests[i] = undefined;
          showRequests = true;
        }
      }
      i++;
    });

    if (showRequests) {
      setConflicts((conflicts) => ({
        ...conflicts,
        expertRequests: {
          title: 'Expert Requests',
          existing: existingRequests,
          conflicting: newRequests,
          merge: 'exclusive',
          editDialog: false,
          viewComponent: ExpertRequest,
        },
      }));
    }
  }, [existing, conflicting, conflicts]);

  const extractInternalNetworks = (profile) => {
    const networks = groups.map((g) => g.internal_network).filter(Boolean);
    const ines = profile.expert_internal_networks || [];
    let sourcesNetwork = [];

    if (profile.sources) {
      sourcesNetwork = profile.sources
        .filter((source) => source.network_id)
        .map((source) => ({
          network: networks.find((g) => g.id === source.network_id),
        }))
        .filter((ine) => !!ine.network);
    }

    return [...ines, ...sourcesNetwork];
  };

  const addNetworksConflict = useCallback(() => {
    existing.ines = extractInternalNetworks(existing);
    conflicting.ines = extractInternalNetworks(conflicting);

    const existingNetworks = [];
    const newNetworks = [];

    // Put all networks in a single array so they can be sorted
    const allNetworks = [...existing.ines, ...conflicting.ines];

    let i = 0;
    let showNetworks = false;

    allNetworks.forEach((ine) => {
      const { network } = ine;

      const conflictFn = (cIne) => network.id === cIne.network.id;
      const existFn = (x) =>
        x && x.value && x.value.network && network.id === x.value.network.id;

      const isExisting = existing.ines.some(conflictFn);
      const isNew = conflicting.ines.some(conflictFn);

      // Network has already been added as a conflict of another network,
      // let's skip.
      if (isExisting && existingNetworks.some(existFn)) return;
      if (isNew && newNetworks.some(existFn)) return;

      if (isNew) {
        // Conflicting side is selected by default
        const conflict = conflicting.ines.find(conflictFn);
        newNetworks[i] = { value: conflict, selected: true };
        // New network conflicts with existing network
        const current = existing.ines.find(conflictFn);
        if (current && current.id) {
          existingNetworks[i] = { value: current, selected: false };
        } else {
          // No conflict, the other side is empty
          existingNetworks[i] = undefined;
          // Existing and New are not the same, let's show the Networks section
          showNetworks = true;
        }
      } else {
        // Existing side is selected by default unless there is a conflict
        existingNetworks[i] = { value: ine, selected: true };
        // Existing network conflicts with new network
        const conflict = conflicting.ines.find(conflictFn);
        if (conflict) {
          newNetworks[i] = { value: conflict, selected: true };
          existingNetworks[i].selected = false;
        } else {
          newNetworks[i] = undefined;
          showNetworks = true;
        }
      }
      i++;
    });

    if (showNetworks) {
      setConflicts((conflicts) => ({
        ...conflicts,
        internalNetworks: {
          title: 'Networks',
          existing: existingNetworks,
          conflicting: newNetworks,
          merge: 'exclusive',
          editDialog: false,
          viewComponent: Network,
        },
      }));
    }
  }, [existing, conflicting, conflicts]);

  const handleSelect = (side, section, merge, index) => {
    const tempConflicts = { ...conflicts };
    const otherSide = side === EXISTING ? CONFLICTING : EXISTING;
    const value =
      index > -1
        ? tempConflicts[section][side][index]
        : tempConflicts[section][side];
    const otherSideValue =
      index > -1
        ? tempConflicts[section][otherSide][index]
        : tempConflicts[section][otherSide];

    value.selected = !value.selected;
    if (merge === 'exclusive' && otherSideValue) {
      otherSideValue.selected = !value.selected;
    }
    setConflicts(tempConflicts);
  };

  const handleEditDialog = (side, section, index) => {
    setEditing({ side, section, index });
  };

  const handleSelectAll = (side) => {
    const tempConflicts = { ...conflicts };
    const otherSide = side === EXISTING ? CONFLICTING : EXISTING;

    Object.keys(tempConflicts).forEach((section) => {
      const c = tempConflicts[section];

      if (Array.isArray(c[side])) {
        c[side].forEach((v, i) => {
          if (c[side][i]) c[side][i].selected = true;
          if (c[otherSide][i]) c[otherSide][i].selected = false;
        });
        return;
      }

      c[side].selected = true;
      c[otherSide].selected = false;
    });

    setConflicts(tempConflicts);
  };

  const allSelected = (existing) => {
    const side = existing ? 'existing' : 'conflicting';

    return Object.keys(conflicts).every((section) => {
      const c = conflicts[section];

      if (Array.isArray(c[side])) {
        return c[side].every((v, i) => !c[side][i] || c[side][i].selected);
      }

      return c[side].selected;
    });
  };

  const handleSave = async () => {
    const merged = {
      destination_id: existing.id,
      source_id: conflicting.id,
    };

    Object.keys(conflicts).forEach((section) => {
      const c = conflicts[section];

      if (Array.isArray(c.existing)) {
        const mergedArray = c.existing
          .map((e, i) => {
            const existing = c.existing[i];
            const conflicting = c.conflicting[i];
            if (existing && existing.selected) return existing.value;
            if (conflicting && conflicting.selected) return conflicting.value;
          })
          .filter(Boolean);
        merged[section] = mergedArray;
        return;
      }

      Object.keys(c.existing.value).forEach((attr) => {
        const existingValue = c.existing.value[attr];
        const conflictingValue = c.conflicting.value[attr];

        if (Array.isArray(existingValue || conflictingValue)) {
          const mergedArray = {};
          if (c.existing.selected)
            existingValue.forEach((v) => (mergedArray[v] = true));
          if (c.conflicting.selected)
            conflictingValue.forEach((v) => (mergedArray[v] = true));
          merged[attr] = Object.keys(mergedArray);
        } else {
          if (c.existing.selected) merged[attr] = existingValue;
          if (c.conflicting.selected) merged[attr] = conflictingValue;
        }
      });
    });

    closeSaveConfirmation();

    if (merged.internal_cv_url) {
      merged.cv_url = merged.internal_cv_url;
      delete merged.internal_cv_url;
    }

    if (merged.internalNetworks) {
      // Internal Network Expert attached to Profile
      merged.internal_networks_ids = merged.internalNetworks
        .filter((ine) => !!ine.id)
        .map((ine) => ine.id);

      // Network from Profile Sources
      merged.networks_ids = merged.internalNetworks
        .filter((ine) => !ine.id)
        .map((ine) => ine.network.id);

      delete merged.internalNetworks;
    }

    if (merged.expertRequests) {
      // Expert Request Candidates attached to Profile
      merged.expert_request_candidate_ids = merged.expertRequests
        .filter((er) => !!er.id)
        .map((er) => er.id);

      // Expert Request carried from import
      const er = merged.expertRequests.find((er) => !er.id);
      merged.expert_request_id = er ? er.request_id : undefined;

      delete merged.expertRequests;
    }

    merged.picture_url = merged.raw_picture_url;
    delete merged.raw_picture_url;

    try {
      await mergeProfiles(merged);

      const redirectTo = getSameOriginPath(existing.html_url);
      if (redirectTo) {
        navigate(redirectTo);
      } else {
        navigate(redirectTo, { replace: true });
      }
    } catch (err) {
      notify(
        `An error occurred when merging profiles. (error ${err.message})`,
        'error'
      );
      throw err;
    }
  };

  const closeEditForm = () => setEditing(null);

  const handleEdit = (value, section, side, index) => {
    if (Array.isArray(conflicts[section][side])) {
      conflicts[section][side][index].value = value;
    } else {
      conflicts[section][side].value = value;
    }
    setConflicts(conflicts);
  };

  const openSaveConfirmation = () => setSaveConfirmationOpen(true);

  const closeSaveConfirmation = () => setSaveConfirmationOpen(false);

  const existingSource = existing.sources[existing.sources.length - 1];
  const conflictingSource = conflicting.sources[conflicting.sources.length - 1];

  return (
    <div>
      <ProfileEditForm
        editing={editing}
        conflicts={conflicts}
        onClose={closeEditForm}
        onSubmit={handleEdit}
      />

      <Dialog
        open={saveConfirmationOpen}
        title="Merge Profiles"
        onCancel={closeSaveConfirmation}
        onConfirm={handleSave}
        confirmLabel="Merge"
        cancelLabel="Cancel"
      >
        All selected fields will be merged with the existing profile. The
        conflicting profile will be deleted.
      </Dialog>

      <div className={s.header}>
        <div className={s.headerText}>Merging Profiles</div>
        <div>
          <Button label="Save" onClick={openSaveConfirmation} size="medium" />
        </div>
      </div>

      <div className={s.helpText}>
        Please decide which version of this profile you’d like to use.
      </div>

      <Divider />

      <div className={s.sources}>
        <Source
          side={EXISTING}
          checked={allSelected(true)}
          title="Original"
          href={existing.html_url}
          source={existingSource}
          onClick={handleSelectAll}
        />
        <Source
          side={CONFLICTING}
          checked={allSelected(false)}
          title="Update"
          href={conflicting.html_url}
          source={conflictingSource}
          onClick={handleSelectAll}
        />
      </div>

      <Divider />

      {Object.keys(conflicts).map((section) => {
        const c = conflicts[section];
        return (
          <ConflictSection key={section} title={c.title}>
            {Array.isArray(c.existing) ? (
              c.existing.map((e, i) => (
                // assuming existing and conflicting arrays are the same size
                <Conflict
                  key={`${section}${JSON.stringify(
                    c.existing[i]
                  )}${JSON.stringify(c.conflicting[i])}`}
                  section={section}
                  index={i}
                  existing={c.existing[i]}
                  conflicting={c.conflicting[i]}
                  component={c.viewComponent}
                  merge={c.merge}
                  onSelect={handleSelect}
                  onEditDialog={c.editDialog ? handleEditDialog : undefined}
                  onEdit={handleEdit}
                  link={c.link}
                />
              ))
            ) : (
              <Conflict
                section={section}
                existing={c.existing}
                conflicting={c.conflicting}
                component={c.viewComponent}
                merge={c.merge}
                onSelect={handleSelect}
                onEditDialog={c.editDialog ? handleEditDialog : undefined}
                onEdit={handleEdit}
                link={c.link}
              />
            )}
          </ConflictSection>
        );
      })}
    </div>
  );
}

ProfileMerge = connect(
  (state) => ({
    groups: ((state.groups.networks || {}).edges || []).map((g) => g.node),
    expertRequests: (state.expertRequests.all.edges || []).map((e) => e.node),
  }),
  {
    mergeProfiles,
    notify,
  }
)(ProfileMerge);

ProfileMerge = ProfileMerge;

export default ProfileMerge;

function ConflictSection({ title, children }) {
  return (
    <div className={s.conflicts}>
      <div className={s.conflictsTitle}>{title}</div>
      <div className={s.conflictsBody}>{children}</div>
    </div>
  );
}

function Conflict({
  section,
  existing,
  conflicting,
  index,
  component,
  merge,
  onSelect,
  onEditDialog,
  onEdit,
  link,
}) {
  return (
    <div className={s.conflict}>
      <ConflictSide
        side={EXISTING}
        section={section}
        conflict={existing}
        index={index}
        component={component}
        merge={merge}
        onSelect={onSelect}
        onEditDialog={onEditDialog}
        onEdit={onEdit}
        link={link}
      />
      <ConflictSide
        side={CONFLICTING}
        section={section}
        conflict={conflicting}
        index={index}
        component={component}
        merge={merge}
        onSelect={onSelect}
        onEditDialog={onEditDialog}
        onEdit={onEdit}
        link={link}
      />
    </div>
  );
}

const useConflictStyles = makeStyles(() => ({
  labelRoot: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0 10px',
    marginRight: 0,
  },
  labelContent: ({ checked }) => ({
    paddingLeft: 15,
    color: checked ? black : darkGray,
    fontWeight: checked ? 500 : 400,
    wordBreak: 'break-word',
  }),
  checkboxRoot: {
    padding: 0,
  },
}));

function Checkbox({ checked, onChange, label }) {
  const classes = useConflictStyles({ checked });

  return (
    <FormControlLabel
      control={
        <MaterialCheckbox
          checked={checked}
          onChange={onChange}
          classes={{ root: classes.checkboxRoot }}
        />
      }
      label={label}
      classes={{ root: classes.labelRoot, label: classes.labelContent }}
    />
  );
}

function ConflictSide({
  side,
  section,
  index,
  conflict,
  component,
  merge,
  onSelect,
  onEditDialog,
  onEdit,
  link,
}) {
  const isLeft = side === EXISTING;

  if (!conflict) {
    return (
      <div
        className={cx(s.conflictSideEmpty, { [s.conflictSideLeft]: isLeft })}
      />
    );
  }

  const Component = component;

  const content = Component ? (
    <Component
      side={side}
      section={section}
      index={index}
      value={conflict.value}
      onEdit={onEdit}
    />
  ) : (
    conflict.value[section]
  );

  return (
    <div className={cx(s.conflictSide, { [s.conflictSideLeft]: isLeft })}>
      <div className={s.conflictSideSelect}>
        <Checkbox
          checked={conflict.selected}
          onChange={() => onSelect(side, section, merge, index)}
          label={content}
        />
      </div>
      {(onEditDialog || link) && (
        <div className={s.conflictSideAction}>
          {onEditDialog && (
            <EditIcon onClick={() => onEditDialog(side, section, index)} />
          )}
          {link && (
            <a
              href={conflict.value[section]}
              target="_blank"
              rel="noreferrer"
              style={{ display: 'inline-block', marginLeft: 5 }}
            >
              <MaterialIcon icon="open_in_new" color={darkGreen} size={22} />
            </a>
          )}
        </div>
      )}
    </div>
  );
}

function Source({ side, title, href, source, onClick, checked }) {
  const isLeft = side === EXISTING;

  const sourceId =
    source &&
    ((source.agent_id &&
      source.agent_profile_id &&
      `${source.agent_id}:${source.agent_profile_id}`) ||
      source.source_id ||
      source.agent_id);

  return (
    <div className={cx(s.source, { [s.sourceLeft]: isLeft })}>
      <div className={s.sourceTitle}>
        {title}
        {href && (
          <a
            className={s.profileLink}
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            <MaterialIcon icon="launch" />
          </a>
        )}
      </div>
      <p className={s.sourceSubTitle}>Pick all changes</p>
      <div className={s.sourceBody}>
        <Checkbox
          checked={checked}
          onChange={() => onClick(side)}
          label={`${sourceId || title} ${
            source ? moment(source.created_at).format('YYYY-MM-DD hh:mma') : ''
          }`}
        />
      </div>
    </div>
  );
}

function EditTimezone() {
  return (
    <Field
      fullWidth
      component={SelectTimezone}
      name="timezone"
      label="Time Zone"
    />
  );
}

function ProfilePicture({
  value,
  section,
  side,
  onEdit,
  presignedFilestackURL,
}) {
  const [imageUrl, setImageUrl] = useState(undefined);

  useEffect(() => {
    (async function () {
      const url = await presignedFilestackURL(value.raw_picture_url);
      setImageUrl(url);
    })();
  }, [value.raw_picture_url]);

  return (
    <Image
      editable
      src={imageUrl}
      dimensions={{ height: 220, width: 220 }}
      style={{ zIndex: 999, width: 220, height: 220, boxSizing: 'border-box' }}
      onChange={(file) => onEdit({ raw_picture_url: file.url }, section, side)}
    />
  );
}

ProfilePicture = connect(undefined, {
  presignedFilestackURL,
})(ProfilePicture);

function AvailableMarketplace({ value, section }) {
  return value[section] ? (
    <div>
      <FAIcon icon="user" size={16} /> In Marketplace
    </div>
  ) : (
    <div>
      <div>
        <FAIcon icon="user-slash" size={16} color={red500} /> Not in Marketplace
      </div>
    </div>
  );
}

function HeadlineAndBio({ value }) {
  return (
    <div>
      <div className={s.headline}>{value.title}</div>
      <div className={s.bio}>{value.summary}</div>
    </div>
  );
}

function EditHeadlineAndBio() {
  return (
    <div>
      <Field component={TextField} fullWidth name="title" label="Headline" />
      <Field
        component={TextField}
        fullWidth
        name="summary"
        label="Bio"
        multiline
        minRows={3}
        maxRows={10}
      />
    </div>
  );
}

function LinkedIn({ value }) {
  return (
    <div>
      <div>{value.linkedin_username}</div>
      <div className={s.linkedInUrl}>
        {value.linkedin_url && (
          <a
            className={s.linkedInLink}
            href={value.linkedin_url}
            target="_blank"
            rel="noreferrer"
          >
            {value.linkedin_url}
          </a>
        )}
      </div>
    </div>
  );
}

function EditLinkedIn() {
  return (
    <div>
      <Field
        component={TextField}
        fullWidth
        name="linkedin_username"
        label="Username"
      />
      <Field component={TextField} fullWidth name="linkedin_url" label="URL" />
    </div>
  );
}

function CountryAndCity({ value }) {
  return (
    <div>
      {value.country}
      {value.country ? ', ' : ''}, {value.city}
    </div>
  );
}

function EditCountryAndCity({ allCountries }) {
  return (
    <div>
      <Field
        component={SelectLocation}
        multiple={false}
        name="country"
        label="Country"
        countries={allCountries}
        format={(country) => allCountries.find((c) => c.name === country)}
        parse={(country) => (country && country.name) || ''}
      />
      <Field component={TextField} fullWidth name="city" label="City" />
    </div>
  );
}

function Experience({ value }) {
  return (
    <div className={s.experience}>
      <div>
        {value.organization} • {value.location}
      </div>
      <div>
        {value.title} • (
        <span className={s.experiencePeriod}>
          {formatExperiencePeriod(
            value.start_date,
            value.end_date,
            value.current,
            {
              duration: false,
            }
          )}
        </span>
        )
      </div>
    </div>
  );
}

function ExpertRequest({ value: { expert_request: expertRequest } }) {
  return (
    <div className={s.experience}>
      <div>{expertRequest.name}</div>
    </div>
  );
}

function Network({ value: { network } }) {
  return (
    <div className={s.experience}>
      <div>{network.name}</div>
    </div>
  );
}

function Education({ value }) {
  return (
    <div className={s.experience}>
      <div>
        {value.school} • {value.field_of_study}
      </div>
      <div>
        {value.degree} • (
        <span className={s.experiencePeriod}>
          {formatExperiencePeriod(value.start_date, value.end_date, false, {
            duration: false,
          })}
        </span>
        )
      </div>
    </div>
  );
}

function EditExperience({ change }) {
  return (
    <div>
      <Field component={TextField} fullWidth name="title" label="Title" />
      <Field component={TextField} fullWidth name="location" label="Location" />
      <Field
        component={TextField}
        fullWidth
        name="organization"
        label="Organization"
      />
      <Field
        component={TextField}
        fullWidth
        name="linkedin_url"
        label="LinkedIn"
      />
      <div className={s.editExperienceDates}>
        <Field
          style={{ flex: 1 }}
          component={MonthPickerInput}
          fullWidth
          name="start_date"
          label="Start Date"
          format={(value) => experienceStringToDate(value)}
          parse={(value) => experienceDateToString(value)}
        />
        <Field
          style={{ flex: 1, marginLeft: 20 }}
          component={MonthPickerInput}
          fullWidth
          name="end_date"
          label="End Date"
          format={(value) => experienceStringToDate(value)}
          parse={(value) => experienceDateToString(value)}
        />
        <div style={{ flex: 1 }}>
          <Field
            style={{ marginLeft: 20 }}
            component={FormAdapterCheckbox}
            name="current"
            type="checkbox"
            label="I still work here"
            onChange={(e, value) => {
              change('end_date', null);
              change('current', value);
            }}
          />
        </div>
      </div>
      <Field
        component={TextField}
        fullWidth
        name="description"
        label="Description"
        multiline
        minRows={3}
        maxRows={10}
      />
    </div>
  );
}

function EditEducation() {
  return (
    <div>
      <Field component={TextField} fullWidth name="degree" label="Degree" />
      <Field
        component={TextField}
        fullWidth
        name="field_of_study"
        label="Field of Study"
      />
      <Field component={TextField} fullWidth name="school" label="School" />
      <div className={s.editExperienceDates}>
        <YearPicker
          current
          offset={0}
          name="start_date"
          label="Start year"
          style={{ flex: 1, marginRight: 20 }}
        />

        <YearPicker
          current={false}
          offset={8}
          name="end_date"
          label="End year (or expected)"
          style={{ flex: 1 }}
        />
      </div>
      <Field
        component={TextField}
        fullWidth
        name="description"
        label="Description"
        multiline
        minRows={3}
        maxRows={10}
      />
    </div>
  );
}

function MonthPickerInput({ input, ...other }) {
  return (
    <MonthPicker
      {...other}
      {...input}
      maxDate={new Date()}
      onChange={(date) => input.onChange(date)}
      placeholder="Example: 06/2010"
    />
  );
}

function List({ section, value }) {
  return value[section].map((v) => <div key={v}>{v}</div>);
}

function ListSectors({ section, value, sectors }) {
  return value[section].map((id) => {
    const sector = sectors.find((s) => s.id === id);
    return <div key={id}>{sector.name}</div>;
  });
}
ListSectors = connect((state) => ({ sectors: state.sectors.all }))(ListSectors);

function ListRegions({ section, value, regions }) {
  return value[section].map((id) => {
    const region = regions.find((s) => s.id === id);
    return <div key={id}>{region.name}</div>;
  });
}
ListRegions = connect((state) => ({ regions: state.countries }))(ListRegions);

function EditEmails() {
  return (
    <FieldArray
      name="emails"
      addButtonLabel="Email Address"
      component={EditableList}
    />
  );
}

function EditPhones({ allCountries }) {
  return (
    <FieldArray
      name="phones"
      component={EditableList}
      addButtonLabel="Phone Number"
      inputComponent={PhoneField}
      inputProps={{ allCountries }}
    />
  );
}

function PhoneField({ name, allCountries }) {
  return (
    <Field
      component={PhoneInput}
      type="tel"
      name={name}
      className={s.phone}
      allCountries={allCountries}
      showExampleOnError
    />
  );
}

function EditKeywords({ array }) {
  return (
    <Field
      component={KeywordInput}
      maxLength={64}
      name="keywords"
      placeholder="Click to add keywords"
      label="Keywords"
      onRequestAdd={(v) => array.push('keywords', v)}
      onRequestDelete={(v, i) => array.remove('keywords', i)}
    />
  );
}

function metaFormatter(meta) {
  return function (values) {
    return (
      values &&
      values
        .map((value) => meta.find((v) => v.id.toString() === value))
        .filter(Boolean)
    );
  };
}

function EditSectors({ allSectors }) {
  return (
    <Field
      component={SelectSector}
      format={metaFormatter(allSectors)}
      parse={parseId}
      name="sector_ids"
      sectors={allSectors}
      placeholder="Click to add sectors"
      label="Sectors"
    />
  );
}

function EditRegions({ allCountries }) {
  return (
    <Field
      component={SelectLocation}
      multiple
      format={metaFormatter(allCountries)}
      parse={parseId}
      name="region_ids"
      countries={allCountries}
      TextFieldProps={{
        label: 'Regions',
        placeholder: 'Click to add regions',
      }}
    />
  );
}

class ProfileEditForm extends PureComponent {
  handleReset = () => this.props.reset();

  handleSubmit = (values) => {
    const { onSubmit, editing } = this.props;
    onSubmit(values, editing.section, editing.side, editing.index);
  };

  render() {
    const {
      handleSubmit,
      editing,
      conflicts,
      array,
      change,
      allCountries,
      allSectors,
      onClose,
    } = this.props;

    const conflict = editing && conflicts[editing.section];
    const Component = conflict && conflict.editComponent;
    const title = conflict && `Edit ${conflict.title}`;

    return (
      <EditDialog
        title={title}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.handleReset}
        open={!!editing}
        onClose={onClose}
      >
        {conflict &&
          (Component ? (
            <Component
              section={editing.section}
              array={array}
              change={change}
              allCountries={allCountries}
              allSectors={allSectors}
            />
          ) : (
            <Field
              component={TextField}
              fullWidth
              // if no edit component is specified, assuming the section is the
              // same as the field name
              name={editing.section}
              label={conflict.title}
            />
          ))}
      </EditDialog>
    );
  }
}

ProfileEditForm = reduxForm({
  form: 'profileEditForm',
  enableReinitialize: true,
})(ProfileEditForm);

ProfileEditForm = connect((state, ownProps) => {
  const { editing, conflicts } = ownProps;

  if (!editing) return {};

  const conflictSide = conflicts[editing.section][editing.side];

  return {
    allCountries: state.countries,
    allSectors: state.sectors.all,
    initialValues: Array.isArray(conflictSide)
      ? conflictSide[editing.index].value
      : conflictSide.value,
  };
})(ProfileEditForm);
