import * as Sentry from '@sentry/browser';
import LokkaTransport from 'lokka/transport';
import fetch from './fetch';

const PERMISSION_ERROR_MESSAGE =
  "You don't have permission to perform this action.";
const OTP_ERROR_MESSAGES = {
  otp_required: 'otp required',
  otp_enrollment_required: 'otp enrollment required',
};

function hasError(errors, message) {
  return (errors || []).some((e) => e.message && e.message.startsWith(message));
}

function isJSONContentType(contentType) {
  const parts = contentType.split(';');
  return ['application/json'].includes(parts[0]);
}

export class TransportError extends Error {
  constructor(message, payload) {
    super(message);
    this.name = 'TransportError';
    this.extra = { payload };
  }
}

export class UnauthorizedError extends Error {
  constructor(message, remainingAttempts) {
    super(message);
    this.name = this.constructor.name;
    this.remainingAttempts = remainingAttempts;
    this.isPermissionError = true;
  }
}

export class APIError extends Error {
  constructor(errors, data, query, variables) {
    const payload = { query, variables };
    const { message } = errors[0];
    super(`GraphQL Error: ${message}`);
    this.rawError = errors;
    this.rawData = data;
    this.query = query;
    this.extra = { payload, errors };
    this.variables = variables;
    this.isPermissionError = hasError(errors, PERMISSION_ERROR_MESSAGE);
  }
}

export default class Transport extends LokkaTransport {
  constructor(endpoint, options = {}) {
    if (!endpoint) {
      throw new Error('endpoint is required!');
    }

    super();
    this._httpOptions = {
      auth: options.auth,
      headers: options.headers || {},
      credentials: options.credentials,
    };
    this.endpoint = endpoint;
  }

  _buildOptions(payload) {
    const options = {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      // To pass cookies to the server. (supports CORS as well)
      credentials: 'include',
    };

    // use delete property for backward compatibility
    if (this._httpOptions.credentials === false) {
      delete options.credentials;
    }

    Object.assign(options.headers, this._httpOptions.headers);
    return options;
  }

  async send(query, variables, operationName) {
    Sentry.addBreadcrumb({
      message: `Requesting ${this.endpoint}`,
      category: 'graphql',
      level: 'info',
    });

    const payload = { query, variables, operationName };
    let response;
    try {
      response = await fetch(this.endpoint, this._buildOptions(payload));
    } catch (err) {
      if (err.message !== 'Failed to fetch') {
        throw err;
      }
      throw new TransportError(err.message);
    }
    const body = await response.text();
    const contentType = response.headers.get('content-type');
    const json = isJSONContentType(contentType) ? JSON.parse(body) : undefined;

    switch (response.status) {
      case 401:
        throw new UnauthorizedError(json?.reason, json?.remaining_attempts);
      case 200:
      case 422:
        const { data, errors } = json;
        if (errors) {
          for (const [key, message] of Object.entries(OTP_ERROR_MESSAGES)) {
            if (hasError(errors, message)) {
              throw new UnauthorizedError(key);
            }
          }

          throw new APIError(errors, data, query, variables);
        }
        return data;
      default:
        // 500 errors won't actually reach here because of CORS issues
        throw new TransportError(response.statusText);
    }
  }
}
