import React, {
  Component,
  PureComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Waypoint from 'react-waypoint';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import MaterialButton from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

import pick from 'lodash.pick';
import IconButton from '../../components/IconButton';
import Divider from '../../components/Divider';
import LayoutPage from '../../components/Layout/LayoutPage';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import Button from '../../components/Button';
import Link from '../../components/Link';
import CandidateWidgetHorizontal from './CandidateWidgetHorizontal/CandidateWidgetHorizontal';
import { SendMessageDialog } from '../../components/MessageTemplate';
import ActivityLog from '../../components/ActivityLog';
import EditExpertRequest from './EditExpertRequest';
import CircularProgress from '../../components/CircularProgress';
import Name from '../../components/Forms/ExpertRequest/Name';
import Project from '../../components/Forms/ExpertRequest/Project';
import EditIcon from '../../components/EditIcon';
import MediaQuery from '../../components/MediaQuery';
import ConsultationList from '../../components/ConsultationList';
import ShareableLink from '../../components/ShareableLink';
import MaterialIcon from '../../components/Icon/MaterialIcon';
import EmptyMessage from '../../components/EmptyMessage';
import CountBox from '../../components/CountBox';
import DeleteExpertRequestDialog from '../../components/DeleteDialog/DeleteExpertRequestDialog';
import MemberRequests from '../../components/MemberRequests/Project';
import ProjectBar from '../../components/ProjectBar';
import FAIcon from '../../components/Icon/FAIcon';
import IconMenu from '../../components/IconMenu/IconMenu';
import Details from '../../components/Forms/ExpertRequest/DetailsNew';
import SubmitContainer from '../../components/Forms/ExpertRequest/SubmitContainer';
import ReferFriend from '../../components/ReferFriend/ReferFriend';
import SelectedProfilesBar from '../search/SelectedProfilesBar';
import { SCREEN_SM } from '../../constants';
import { buildRefUrl } from '../../core/expertRequest';
import { sortMembers } from '../../core/project';
import history from '../../core/history';
import { black, sand, darkBrown, darkGray } from '../../core/colors';
import {
  removeExpertRequestCandidate,
  saveExpertRequest,
  updateExpertRequestCandidate,
  updateExpertRequestState,
} from '../../actions/expertRequest';
import {
  invalidateFetchedProjectsCache,
  updateProjectMember,
} from '../../actions/project';
import { setAddress } from '../../actions/address';
import { notify } from '../../actions/ui';
import { add, removeAt } from '../../reducers/utils';
import s from './ExpertRequest.module.scss';
import CloseExpertRequestDialog from '../../components/CloseExpertRequestDialog';
import { useApp } from '../../hooks/useAppContext';
import { useNavigate } from 'react-router-dom';

const dialogs = {
  name: {
    component: Name,
    title: 'Edit Name',
  },
  project: {
    component: Project,
    title: 'Edit Associated Project',
    onSubmit: ({ store }) => {
      store.dispatch(invalidateFetchedProjectsCache());
    },
  },
};

function sortDates(a, b) {
  return new Date(a).getTime() - new Date(b).getTime();
}

class Promo extends PureComponent {
  render() {
    const { title, children } = this.props;
    return (
      <div className={s.promo}>
        <div className={s.promoHeader}>
          <h3 className={s.promoTitle}>{title}</h3>
        </div>
        <div className={s.promoBody}>{children}</div>
      </div>
    );
  }
}

class RMStartPromo extends PureComponent {
  static contextTypes = {
    location: PropTypes.object,
  };

  render() {
    const { location } = this.context;
    const { viewer, expertRequest } = this.props;
    const titles = [
      'May the force be with you on this match.',
      'Good luck and godspeed, soldier.',
      'I feel the need... the need for speed.',
      'If you match them, they will come.',
      "Toto, I've a feeling we're not in Kansas anymore.",
      'Keep your friends close, but your experts closer.',
      'Go ahead, make my match.',
      'We love the smell of expert matches in the morning.',
      'Happy matching! And may the odds be ever in your favor.',
      "Life is like a box of matches. You never know who you're gonna get.",
      'To match, or not to match, that is the question.',
      'Match or match not. There is no try.',
      'Keep calm and match on.',
      "I'll be back... with a match.",
      "I'll make the expert an offer he can't refuse.",
      'Keep your friends close, but your experts closer.',
      'Not all those who wander are lost... some are just seeking the right expert.',
      'Matchmaker, matchmaker, make me a match, find me an expert, catch me a catch.',
    ];

    const url = buildRefUrl(location, expertRequest, viewer.id, 'linkedin');
    const isAdmin = viewer.admin;

    const title = titles[parseInt(expertRequest.id) % titles.length];
    return (
      <Promo title={title}>
        <div style={{ marginBottom: 10 }}>
          <a href="https://www.notion.so/Fulfillment-Team-Wiki-d09340631e5c42f98e086dba51a213c9">
            Fulfillment Team Wiki
          </a>
        </div>
        <div style={{ marginBottom: 24 }}>
          <ShareableLink
            title="LinkedIn referral URL"
            url={url}
            enableShorten={isAdmin}
          />
        </div>
        <Button
          href="https://www.linkedin.com/search/results/people/"
          target="_blank"
          startIcon="binoculars"
          color="lightTan"
          size="medium"
          style={{ color: black }}
        >
          Start Searching
        </Button>
      </Promo>
    );
  }
}

RMStartPromo = connect((state) => ({
  viewer: state.viewer,
}))(RMStartPromo);

class ClientPromo extends PureComponent {
  render() {
    return (
      <Promo title="We're working on finding your expert matches...">
        <p>Need to make a change to this expert request?</p>
        <Button href="mailto:hello@onfrontiers.com">Contact Support</Button>
      </Promo>
    );
  }
}

class CountBoxes extends PureComponent {
  static boxes = {
    suggestions: [
      {
        id: 'suggestions-suggested',
        label: 'Suggested',
        states: ['suggested_by_platform', 'suggested_by_research'],
        hiddenFromClients: true,
      },
      {
        id: 'suggestions-contacted',
        label: 'Contacted',
        states: ['contacted'],
        hiddenFromClients: true,
      },
      {
        id: 'suggestions-interested',
        label: 'Interested',
        states: ['interested'],
        hiddenFromClients: true,
      },
      {
        id: 'suggestions-rejected',
        label: 'Rejected',
        states: ['rejected_suggestion'],
        hiddenFromClients: true,
        warn: true,
      },
    ],
    experts: [
      {
        id: 'experts-polishing',
        label: 'Polishing',
        states: ['polishing'],
        hiddenFromClients: true,
      },
      {
        id: 'experts-vetting',
        label: 'Vetting',
        states: ['vetting'],
      },
      {
        id: 'experts-verified',
        label: 'Verified',
        states: ['verified'],
      },
      {
        id: 'experts-matched',
        label: 'Matched',
        states: ['matched'],
      },
      {
        id: 'experts-rejected',
        label: 'Rejected',
        states: ['rejected_by_client', 'rejected_by_research'],
        warn: true,
      },
    ],
  };

  constructor(props) {
    super();
    this.state = {
      selected: props.selectedBoxes || { suggestions: {}, experts: {} },
    };
  }

  postClick = () => {
    const { onClick } = this.props;
    const { selected } = this.state;

    const selectedStatesFor = (section) =>
      CountBoxes.boxes[section].reduce((acc, box) => {
        if (!selected[section][box.id]) return acc;
        return [...acc, ...box.states];
      }, []);

    onClick(
      {
        suggestions: selectedStatesFor('suggestions'),
        experts: selectedStatesFor('experts'),
      },
      selected
    );
  };

  handleClick = (event, box) => {
    event.preventDefault();
    const { section } = this.props;

    this.setState(
      (prevState) => ({
        selected: {
          ...prevState.selected,
          [section]: {
            ...prevState.selected[section],
            [box.id]: !prevState.selected[section][box.id],
          },
        },
      }),
      () => this.postClick()
    );
  };

  sumCounts = (expertCounts, states) => {
    const matchingCounts = Object.values(pick(expertCounts, states));
    return matchingCounts.length > 0
      ? matchingCounts.reduce((a, b) => a + b)
      : 0;
  };

  render() {
    const { section, expertCounts, isClient } = this.props;
    const { selected } = this.state;
    const boxes = CountBoxes.boxes[section].filter(
      (x) => !(isClient && x.hiddenFromClients)
    );

    if (!expertCounts) {
      return null;
    }

    return (
      <div className={s.countBoxes}>
        {boxes.map((b) => (
          <CountBox
            className={s.countBox}
            grayOutZero
            key={b.id}
            selected={selected[section][b.id]}
            label={b.label}
            count={this.sumCounts(expertCounts, b.states)}
            warn={b.warn}
            labelClassName={s.countBoxLabel}
            onClick={(event) => this.handleClick(event, b)}
          />
        ))}
      </div>
    );
  }
}

const ADDRESS_TAKEN_ERROR = 'GraphQL Error: address already taken';

function ExpertRequest(props) {
  const { store } = useApp();
  const navigate = useNavigate();

  const [editDialog, setEditDialog] = useState(null);
  const [stateSection, setSection] = useState(props.section || 'experts');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedStates, setSelectedStates] = useState({
    suggestions: [],
    experts: [],
  });
  const [selectedBoxes, setSelectedBoxes] = useState(null);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [sendMessageDialogOpen, setSendMessageDialogOpen] = useState(false);
  const [closeExpertRequestDialogOpen, setCloseExpertRequestDialogOpen] =
    useState(false);
  const candidateRefs = useRef(() => {
    const { matchedCandidates, suggestedCandidates } = props.expertRequest;
    const cands = (matchedCandidates || []).concat(suggestedCandidates || []);
    const refs = {};
    cands.forEach((c) => (refs[c.id] = React.createRef()));
    return refs;
  });

  const {
    viewer,
    candidateId,
    notify,
    expertRequest,
    updateExpertRequestState,
    saveExpertRequest,
    setAddress,
    removeExpertRequestCandidate,
    updateExpertRequestCandidate,
    isGoodMatch,
    fetchMoreCandidates,
  } = props;

  useEffect(() => {
    const selectedCandRef = candidateRefs.current[candidateId]?.current;

    if (selectedCandRef) {
      const rect = selectedCandRef.getBoundingClientRect();
      const headerOffset = -45;
      const elementPosition = rect.top;
      const offsetPosition = elementPosition + headerOffset;
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }

    async function fetchData() {
      await Promise.all(
        Details.fetch.map((f) => store.dispatch(f({ viewer })))
      );
    }

    fetchData();
  }, []);

  const openDialog = useCallback(
    async (editDialog) => {
      const { component } = dialogs[editDialog];

      if (component.fetch && Array.isArray(component.fetch)) {
        await Promise.all(
          component.fetch.map((f) => store.dispatch(f({ viewer })))
        );
      } else if (component.fetch) {
        await store.dispatch(component.fetch({ viewer }));
      }

      setEditDialog(editDialog);
    },
    [viewer]
  );

  const closeDialog = useCallback(
    (message, error) => {
      setEditDialog(null);

      if (message) {
        notify(message, error ? 'error' : 'success');
      }
    },
    [notify]
  );

  const handleUpdateState = useCallback(
    (closeReason) => {
      const state = expertRequest.state === 'open' ? 'closed' : 'open';
      updateExpertRequestState(expertRequest.id, state, closeReason);
      if (state === 'closed') navigate('/expert_requests');
    },
    [expertRequest, updateExpertRequestState]
  );

  const openExpertsRequestDialog = () => {
    setCloseExpertRequestDialogOpen(true);
  };

  const closeExpertRequestDialog = () => {
    setCloseExpertRequestDialogOpen(false);
  };

  const confirmDelete = () => setDeleteConfirmationOpen(true);
  const closeConfirmDelete = () => setDeleteConfirmationOpen(false);

  const selectProfile = (profile, selected) => {
    if (selected) {
      setSelectedProfiles((state) =>
        add(state, profile, (p) => p.id === profile.id)
      );
    } else {
      setSelectedProfiles((state) =>
        removeAt(
          state,
          state.findIndex((p) => p.id === profile.id)
        )
      );
    }
  };

  const clearProfileSelection = () => {
    setSelectedProfiles([]);
  };

  const handleSubmit = useCallback(
    async (values, _, callback) => {
      if (!viewer.phone && values.phone) {
        try {
          setAddress(viewer.profile.id, 'phone', values.phone, true);
        } catch (err) {
          if (err.message.startsWith(ADDRESS_TAKEN_ERROR)) {
            callback({
              phone: 'Already in use, please use another',
            });
            return;
          }
          notify('Error saving phone number.', 'error');
        }
      }

      const permissions = expertRequest.permissions || [];
      const canEditAdminFields = permissions.includes('update_admin_fields');
      const canEditQueries = permissions.includes('update_queries');

      try {
        await saveExpertRequest(values, {
          includeAdminFields: canEditAdminFields,
          includeQueries: canEditQueries,
        });
      } catch (err) {
        notify('An error occurred when updating the expert request.', 'error');
      }
      notify('Saved successfully.', 'success');
    },
    [viewer, saveExpertRequest, setAddress, notify, expertRequest]
  );

  if (!expertRequest) return <div />;

  const { name, project, stats } = expertRequest;
  const selectedSection = stateSection;
  const permissions = expertRequest.permissions || [];
  const canEdit = permissions.includes('update');
  const canEditQueries = permissions.includes('update_queries');
  const canAddAttachment = permissions.includes('add_attachment');

  const projectMembers = project.members || [];

  const isResearchTeam = projectMembers.some(
    (m) =>
      m.user &&
      m.user.id === viewer.id &&
      (m.role === 'manager' || m.role === 'associate')
  );
  const isClient = projectMembers.some(
    (m) =>
      m.user &&
      m.user.id === viewer.id &&
      (m.role === 'owner' || m.role === 'member')
  );
  const hasAllPermissions = viewer.admin || isResearchTeam;

  const membersToShow = project.members
    .filter((m) => m.state === 'active')
    .sort((a, b) => {
      const roles = ['manager', 'associate', 'owner', 'member', 'viewer'];
      return roles.indexOf(a.role) - roles.indexOf(b.role);
    });

  const memberRequests = sortMembers(
    projectMembers.filter((m) => m.state === 'awaiting_approval')
  );

  const suggestionsTabAvailable =
    hasAllPermissions ||
    (expertRequest.suggestedCandidates &&
      expertRequest.suggestedCandidates.length > 0);

  const section =
    selectedSection === 'suggestions' && !suggestionsTabAvailable
      ? 'experts'
      : selectedSection;

  const selectedStatesList =
    section === 'suggestions'
      ? selectedStates.suggestions
      : selectedStates.experts;
  let candidates =
    section === 'suggestions'
      ? expertRequest.suggestedCandidates
      : expertRequest.matchedCandidates;
  if (selectedStatesList.length > 0) {
    candidates = candidates?.filter((c) =>
      selectedStatesList.includes(c.state)
    );
  }
  candidates = candidates || [];

  const candidatePageInfo =
    section === 'suggestions'
      ? expertRequest.suggestedCandidatesPageInfo
      : expertRequest.matchedCandidatesPageInfo;

  const fetchMoreCandidatesInternal = () => {
    return fetchMoreCandidates(
      section === 'suggestions' ? 'suggested' : 'matched',
      candidatePageInfo
    );
  };

  const showClientPromo =
    expertRequest.loadedCandidates &&
    candidates.filter(
      (e) =>
        [
          'suggested_by_platform',
          'suggested_by_research',
          'rejected_suggestion',
        ].indexOf(e.state) < 0
    ).length === 0;

  return (
    <MediaQuery maxWidth={SCREEN_SM}>
      {(isMobileVersion) => {
        const contextActions = canEdit && (
          <div className={s.contextActions}>
            <IconMenu
              iconElement={
                <IconButton style={{ height: 24 }}>
                  <FAIcon icon="cog" color={darkBrown} />
                </IconButton>
              }
            >
              <MenuItem
                onClick={() => openDialog('project')}
                disabled={!permissions.includes('update')}
              >
                Edit Associated Project
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (expertRequest.state === 'open') {
                    openExpertsRequestDialog();
                  } else {
                    handleUpdateState();
                  }
                }}
                disabled={!permissions.includes('update')}
              >
                {expertRequest.state === 'open' ? 'Close' : 'Reopen'}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  navigate(
                    `/request_expert/details?copy_from=${expertRequest.id}`
                  )
                }
              >
                Make a Copy
              </MenuItem>
              <MenuItem
                onClick={confirmDelete}
                disabled={
                  !permissions.includes('delete') || (stats && stats.calls > 0)
                }
              >
                Delete Request
              </MenuItem>
            </IconMenu>
          </div>
        );

        return (
          <LayoutPage showNav selected="expert_requests">
            <ConsultationsStarting />

            <ProjectBar
              showSettings
              viewer={viewer}
              project={project}
              members={membersToShow}
            />

            {expertRequest.state === 'closed' && (
              <div className={s.status}>
                <MaterialIcon
                  color={darkGray}
                  className={s.statusIcon}
                  icon="history"
                />
                <span className={s.statusText}>Closed</span>
              </div>
            )}

            <div>
              <div className={s.headerLabel}>Expert Request Title:</div>
              <div className={s.header}>
                <h3 className={s.name}>{name}</h3>
                {canEdit && (
                  <EditIcon
                    onClick={() => openDialog('name')}
                    style={{ marginLeft: 10 }}
                  />
                )}
                {contextActions}
              </div>
            </div>

            <MemberRequests
              projectId={project.id}
              memberRequests={memberRequests}
            />

            <div className={s.content}>
              <div className={s.section}>
                <Tabs
                  value={section}
                  onChange={(e, section) => setSection(section)}
                  variant="scrollable"
                >
                  <Tab
                    value="experts"
                    label="Matched Experts"
                    id="expertRequestsMatchedExpertsTab"
                  />
                  {suggestionsTabAvailable && (
                    <Tab value="suggestions" label="Suggested Experts" />
                  )}
                  <Tab value="consultations" label="Consultations" />
                  <Tab
                    id="expertRequestsAdminRequestDetails"
                    value="details"
                    label="Request Details"
                  />
                </Tabs>

                <div className={s.sectionContent}>
                  <div className={s.sectionItems}>
                    {['suggestions', 'experts'].includes(section) && (
                      <div>
                        {!selectedStates[section].length &&
                          (viewer.admin || isResearchTeam
                            ? section === 'suggestions' && (
                                <RMStartPromo expertRequest={expertRequest} />
                              )
                            : showClientPromo && <ClientPromo />)}
                        {candidates.map((c) => (
                          <CandidateWidgetHorizontal
                            key={c.id}
                            showKeywords
                            candidate={c}
                            expertRequest={expertRequest}
                            viewer={viewer}
                            canConfirmMatch={isClient}
                            style={{ padding: 15, marginBottom: 20 }}
                            onUpdate={(m) =>
                              updateExpertRequestCandidate(expertRequest.id, m)
                            }
                            onRemove={
                              hasAllPermissions &&
                              (() =>
                                removeExpertRequestCandidate(
                                  expertRequest.id,
                                  c
                                ))
                            }
                            onSelect={selectProfile}
                            selected={selectedProfiles.includes(c.profile)}
                            isGoodMatch={
                              isGoodMatch && candidateId === c.id
                                ? isGoodMatch
                                : undefined
                            }
                            ref={candidateRefs[c.id]}
                          />
                        ))}
                        {expertRequest.loadingCandidates && (
                          <div className={s.loading}>
                            <CircularProgress />
                          </div>
                        )}
                        {!expertRequest.loadingCandidates &&
                          candidatePageInfo?.hasNextPage && (
                            <Waypoint onEnter={fetchMoreCandidatesInternal} />
                          )}
                        {expertRequest.loadedCandidates &&
                          !candidates.length && (
                            <EmptyMessage
                              style={{ padding: 20, backgroundColor: sand }}
                              titleStyle={{ fontSize: 24 }}
                              bodyStyle={{ fontSize: 16 }}
                              border={false}
                              title="Request another expert for this project"
                              body="Looking for another type of expertise for the same project?"
                              action={
                                <div>
                                  <Link
                                    to={`/request_expert?project_id=${project.id}`}
                                  >
                                    <MaterialButton color="secondary">
                                      Add New Request
                                    </MaterialButton>
                                  </Link>
                                  <Link
                                    to={`/request_expert/details?copy_from=${expertRequest.id}`}
                                  >
                                    <MaterialButton color="secondary">
                                      Make a Copy
                                    </MaterialButton>
                                  </Link>
                                </div>
                              }
                            />
                          )}
                        <SelectedProfilesBar
                          onRemove={(candidate) =>
                            selectProfile(candidate, false)
                          }
                          onClear={() => clearProfileSelection()}
                          profiles={selectedProfiles}
                        >
                          {viewer.admin && (
                            <div>
                              <Button
                                variant="contained"
                                color="teal"
                                size="medium"
                                startIcon={
                                  <FAIcon
                                    iconSet="fal"
                                    icon="comment-alt-lines"
                                    size={20}
                                  />
                                }
                                onClick={() => setSendMessageDialogOpen(true)}
                                style={{
                                  marginRight: 10,
                                  textTransform: 'initial',
                                }}
                              >
                                Send Message
                              </Button>
                              <SendMessageDialog
                                open={sendMessageDialogOpen}
                                onClose={() => setSendMessageDialogOpen(false)}
                                onSend={clearProfileSelection}
                                profiles={selectedProfiles}
                                expertRequestId={expertRequest.id}
                              />
                            </div>
                          )}
                        </SelectedProfilesBar>
                      </div>
                    )}

                    {section === 'consultations' && (
                      <div>
                        <ConsultationList
                          title="Upcoming"
                          consultations={expertRequest.consultations
                            .filter((c) => c.state === 'confirmed')
                            .sort((a, b) =>
                              sortDates(a.starts_at, b.starts_at)
                            )}
                        />

                        <ConsultationList
                          title="Awaiting"
                          consultations={expertRequest.consultations
                            .filter((c) =>
                              [
                                'negotiating_client_time',
                                'negotiating_expert_time',
                                'awaiting_expert_review',
                              ].includes(c.state)
                            )
                            .sort((a, b) =>
                              sortDates(b.created_at, a.created_at)
                            )}
                        />

                        <ConsultationList
                          grid
                          title="Completed"
                          consultations={expertRequest.consultations
                            .filter((c) => c.state === 'completed')
                            .sort((a, b) => sortDates(b.ended_at, a.ended_at))}
                        />

                        <ConsultationList
                          grid
                          title="Canceled"
                          consultations={expertRequest.consultations
                            .filter((c) =>
                              [
                                'canceled',
                                'denied',
                                'expired',
                                'incomplete',
                              ].includes(c.state)
                            )
                            .sort((a, b) =>
                              sortDates(b.canceled_at, a.canceled_at)
                            )}
                        />
                      </div>
                    )}
                    {section === 'details' && (
                      <Form
                        onSubmit={handleSubmit}
                        initialValues={expertRequest}
                        mutators={{
                          ...arrayMutators,
                        }}
                      >
                        {({ handleSubmit, form, values }) => (
                          <Grid
                            className={s.container}
                            container
                            spacing={4}
                            justifyContent="center"
                          >
                            <Grid item md={8} sm={12}>
                              <Details
                                isViewerExpert={false}
                                isViewerAdmin={viewer.admin}
                                showFields={[
                                  'er_type',
                                  'description',
                                  'qualifications',
                                  'questions',
                                  'companies',
                                  'disclosure',
                                  'sectors',
                                  'regions',
                                  'focusAreas',
                                  'group_about',
                                  'instructions_research',
                                  'job_scope',
                                  'opportunity_location',
                                  'attachments',
                                  'tags',
                                  'expected_duration',
                                  'time_done_scoping_call',
                                ]}
                                canEdit={canEdit}
                                canEditQueries={canEditQueries}
                                canAddAttachment={canAddAttachment}
                                change={form.change}
                                values={values}
                                isMobileVersion={isMobileVersion}
                              />
                            </Grid>
                            <Grid
                              item
                              md={4}
                              sm={12}
                              style={{
                                position: 'sticky',
                                top: 0,
                                height: '100%',
                              }}
                            >
                              <SubmitContainer
                                disabled={!canEdit}
                                handleSubmit={handleSubmit}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Form>
                    )}
                  </div>
                  {!['details', 'consultations'].includes(section) && (
                    <div className={s.expertRequestFilters}>
                      <CountBoxes
                        isClient={!viewer.admin && !isResearchTeam}
                        section={section}
                        expertCounts={candidatePageInfo?.metaData}
                        selectedBoxes={selectedBoxes}
                        onClick={(states, boxes) => {
                          setSelectedStates(states);
                          setSelectedBoxes(boxes);
                        }}
                      />
                      {isClient && (
                        <ReferFriend
                          expertRequest={expertRequest}
                          viewer={viewer}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              <CloseExpertRequestDialog
                expertRequestId={expertRequest.id}
                onConfirm={handleUpdateState}
                onReset={closeExpertRequestDialog}
                onCancel={closeExpertRequestDialog}
                onClose={closeExpertRequestDialog}
                open={closeExpertRequestDialogOpen}
              />
              <DeleteExpertRequestDialog
                expertRequestId={expertRequest.id}
                onConfirm={closeConfirmDelete}
                onCancel={closeConfirmDelete}
                open={deleteConfirmationOpen}
                returnTo="/expert_requests"
              />
            </div>

            <Divider style={{ marginTop: 30, marginBottom: 20 }} />

            <div className={s.activity}>
              <ActivityLog
                objectType="expert_request"
                objectId={expertRequest.id}
                actions={['created', 'closed', 'open']}
                store={store}
              />
            </div>

            {editDialog && (
              <EditExpertRequest
                open
                onClose={closeDialog}
                initialValues={{
                  ...expertRequest,
                  project_id: expertRequest.project.id,
                }}
                {...dialogs[editDialog]}
              />
            )}
          </LayoutPage>
        );
      }}
    </MediaQuery>
  );
}

ExpertRequest = ExpertRequest;

ExpertRequest = connect(
  (state, ownProps) => ({
    viewer: state.viewer,
    expertRequest: (
      state.expertRequests.default.edges.find(
        (p) => p.node.id === ownProps.expertRequestId
      ) || {}
    ).node,
    ...ownProps,
  }),
  {
    removeExpertRequestCandidate,
    saveExpertRequest,
    updateExpertRequestCandidate,
    notify,
    updateExpertRequestState,
    updateProjectMember,
    setAddress,
  }
)(ExpertRequest);

export default ExpertRequest;
